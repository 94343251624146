// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  createParamsList () {
    return {'old': [ ], 'new': [ 'name', 'is_permanent']}
  },
  create (config, {name_new, is_permanent_new}) {
    const params = { 'new': { 'name': name_new, 'is_permanent': is_permanent_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/ndcfg/p_port_type_group/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'name'], 'new': [ ]}
  },
  delete (config, {name_old}) {
    const params = { 'new': { }, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/ndcfg/p_port_type_group/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, name, gpk_list, name_list, fetch_limit, fetch_offset, is_permanent, filter_params_dict, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'name': name, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'name_list': (name_list === null) ? null : JSON.stringify(name_list), 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'is_permanent': is_permanent, 'filter_params_dict': filter_params_dict, 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/ndcfg/p_port_type_group/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
  updateParamsList () {
    return {'old': [ 'name'], 'new': [ 'name', 'is_permanent']}
  },
  update (config, {name_old, name_new, is_permanent_new}) {
    const params = { 'new': { 'name': name_new, 'is_permanent': is_permanent_new}, 'old': { 'name': name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/ndcfg/p_port_type_group/update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
}
