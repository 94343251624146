<template>
  <div id="nd_map">
    <h1>{{ $t('views.nd.map.campus_map') }}</h1>
    <b-alert show variant="warning"><b>{{ $t('system.attention') }}!</b> {{ $t('views.nd.map.huge_traffic_notice') }}
    </b-alert>
    <div class="text-center" v-if="!waiting_for_types.every(element => element === null)">
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" :label="$t('system.loading') + '...'"></b-spinner>
    </div>
    <b-form-group v-if="p_port_type_group.length >0" :label="$t('views.nd.map.p_port_type_groups')">
      <b-form-checkbox-group v-model="selected_types">
        <b-form-checkbox :disabled="waiting_for_types.includes(t.name)" switch
                         :color="t.name.toHSL()"
                         :value="t.name"
                         v-for="t in p_port_type_group"
                         v-bind:key="t.name">
                        <span :style="'color:' + t.name.toHSL()">{{ t.name }} <i
                          v-if="waiting_for_types.includes(t.name)">({{ $t('system.loading') }}...)</i></span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </b-form-group>
    <div class="text-center" v-else>
      <b-spinner style="width: 3rem; height: 3rem;" type="grow" :label="$t('system.loading') + '...'"></b-spinner>
    </div>
    <KITMap
      style="height: 80vh; width: 100%"
      :center="{ lat: 49.011232, lng: 8.413414}"
      :zoom="17"
    >
      <template v-slot:map_content>
        <template v-for="s in selected_types">
          <template v-if="s in lwl_inter_bldg_link_pairs">
            <template v-for="p in lwl_inter_bldg_link_pairs[s]">
              <l-polyline v-if="p[0].latlng != null && p[1].latlng != null" v-bind:key="p[0].pk"
                          :lat-lngs="[p[0].latlng, p[1].latlng]" :color="s.toHSL()">
                <l-tooltip>{{ s }}: {{ p[0].mdl_fq_name }} ({{ p[0].mdl_type }}) {{ p[0].name }} &lt;-&gt;
                  {{ p[1].mdl_fq_name }} ({{ p[1].mdl_type }}) {{ p[1].name }}
                </l-tooltip>
              </l-polyline>
            </template>
          </template>
        </template>
      </template>
    </KITMap>
    {{ $t('views.nd.map.buildings_not_displayed') }}
    <ul>
      <template v-for="s in selected_types">
        <template v-if="s in lwl_inter_bldg_link_pairs">
          <template v-for="p in lwl_inter_bldg_link_pairs[s]">
            <li v-bind:key="p[0].pk" v-if="p[0].latlng == null">{{ p[0].mdl_bldg }}</li>
          </template>
        </template>
      </template>
    </ul>
  </div>
</template>

<script>
import {LPolyline, LTooltip} from 'vue2-leaflet'// LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css'

import '@/util/colorutil'
import PPortService from '@/api-services.gen/nd.p_port.js'
import BldgService from '@/api-services.gen/nd.bldg.js'
import PPortTypeGroup from '@/api-services.gen/ndcfg.p_port_type_group.js'
import KITMapData from '@/api-services/kit_map_data.js'
import KITMap from '@/components/KITMap.vue'
import apiutil from '@/util/apiutil'

export default {
  name: 'WiringMap',
  components: {
    KITMap,
    LPolyline,
    LTooltip
  },
  data() {
    return {
      lwl_inter_bldg_link_pairs: {},
      kit_campus_mapping: KITMapData.kit_campus_mapping(),
      p_port_type_group: [],
      p_port_type_group_by_name: null,
      waiting_for_types: [],
      buildings: null,
      selected_types: ['LWL', 'Festkabel/LWL/2*SM', 'Festkabel/LWL/URM/2*SM', 'Festkabel/LWL/2*MM', 'Antennen/gerichtet']
    }
  },
  created() {
    const self = this
    PPortTypeGroup.list(this.$store.state, {}).then(function (data) {
      self.p_port_type_group = data.data[0]
      self.p_port_type_group_by_name = apiutil.dict_by_value_of_array(self.p_port_type_group, 'name')
      self.update_list()
    })
  },
  watch: {
    selected_types: function () {
      this.update_list()
    }
  },
  methods:
    {
      get_bldg_coords_fuzzy(name) {
        if (name in this.buildlings && this.buildlings[name].geo_location && this.buildlings[name].geo_location.lat && this.buildlings[name].geo_location.lng) {
          const loc = this.buildlings[name].geo_location
          return [loc.lat.toString(), loc.lng.toString()]
        }
        for (const b of this.kit_campus_mapping) {
          if (b.title.includes(name)) {
            return b.positionList[0]
          }
          if (name.includes(b.title)) {
            return b.positionList[0]
          }
        }
        return null
      },
      async update_list() {
        this.buildlings = apiutil.dict_by_value_of_array((await BldgService.list(this.$store.state, {})).data[0], 'number')
        const self = this
        for (const type_group of this.selected_types) {
          if (type_group in this.lwl_inter_bldg_link_pairs || this.waiting_for_types.includes(type_group)) {
            continue
          }
          this.waiting_for_types.push(type_group)
          PPortService.list(this.$store.state, {
            type_group: type_group,
            is_connected: true
          }).then(function (data) {
            if (!(type_group in self.lwl_inter_bldg_link_pairs)) {
              const tmp = {}
              tmp[type_group] = []
              self.lwl_inter_bldg_link_pairs = Object.assign({}, self.lwl_inter_bldg_link_pairs, tmp)
            }
            const p_ports = data.data[0]
            const links_by_pk = apiutil.dict_by_value_of_array(data.data[0], 'gpk')
            for (const p of p_ports) {
              // Undefinierte Kabel
              if (p.mdl_bldg != null && (p.mdl_bldg !== links_by_pk[p.connected_gfk].mdl_bldg) && links_by_pk[p.connected_gfk].mdl_bldg != null) {
                const p0 = Object.assign({}, p)
                p0.latlng = self.get_bldg_coords_fuzzy(p0.mdl_bldg)
                const p1 = Object.assign({}, links_by_pk[p.connected_gfk])
                p1.latlng = self.get_bldg_coords_fuzzy(p1.mdl_bldg)
                self.lwl_inter_bldg_link_pairs[type_group].push([p0, p1])
                // Kabel
              } else if (p.dest_connected_gfk in links_by_pk && links_by_pk[p.connected_gfk].protocol === '[passiv]' && p.mdl_bldg != null && (p.mdl_bldg !== links_by_pk[p.dest_connected_gfk].mdl_bldg) && links_by_pk[p.dest_connected_gfk].mdl_bldg != null) {
                const p0 = Object.assign({}, p)
                p0.latlng = self.get_bldg_coords_fuzzy(p0.mdl_bldg)
                const p1 = Object.assign({}, links_by_pk[p.dest_connected_gfk])
                p1.latlng = self.get_bldg_coords_fuzzy(p1.mdl_bldg)
                self.lwl_inter_bldg_link_pairs[type_group].push([p0, p1])
              }
            }
            for (let i = 0; i < self.waiting_for_types.length; i++) {
              if (self.waiting_for_types[i] === type_group) {
                delete self.waiting_for_types[i]
                break
              }
            }
          })
        }
      }
    }
}
</script>

<style scoped>
</style>
