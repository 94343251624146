export default {
  name: 'kit_map_data',
  kit_campus_mapping () {
    return [{
      id: '1',
      title: 'Eiermann-H\u00f6rsaal (20.40)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01097', '8.41095']],
      url: null
    }, {
      id: '2',
      title: 'Sportinstitut-H\u00f6rsaal (40.40)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01580', '8.41636']],
      url: null
    }, {
      id: '3',
      title: 'Hochspannungs-Technik-H\u00f6rsaal (30.35)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01191', '8.41252']],
      url: null
    }, {
      id: '4',
      title: 'Tulla-H\u00f6rsaal (11.40)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01019', '8.41166']],
      url: null
    }, {
      id: '5',
      title: 'Fasanengarten-H\u00f6rsaal (50.35)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01472', '8.42042']],
      url: null
    }, {
      id: '6',
      title: 'Lehmann-H\u00f6rsaal (30.22)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01242', '8.41073']],
      url: null
    }, {
      id: '7',
      title: 'Engesser-H\u00f6rsaal (10.81)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01014', '8.41554']],
      url: null
    }, {
      id: '8',
      title: 'Kollegium am Schloss H\u00f6rsaal (20.13 001)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01234', '8.40708']],
      url: null
    }, {
      id: '9',
      title: 'Elektrotechnik, kleiner H\u00f6rsaal (11.10)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01065', '8.41281']],
      url: null
    }, {
      id: '10',
      title: 'Lichttechnik-H\u00f6rsaal (30.34)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01212', '8.41304']],
      url: null
    }, {
      id: '11',
      title: 'Bauingenieure, gro\u00dfer und kleiner H\u00f6rsaal (10.50)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00962', '8.41700']],
      url: null
    }, {
      id: '12',
      title: 'Benz-H\u00f6rsaal (10.21)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00946', '8.41343']],
      url: null
    }, {
      id: '13',
      title: 'Grashof-H\u00f6rsaal (10.91)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00994', '8.41329']],
      url: null
    }, {
      id: '14',
      title: 'Architektur-H\u00f6rs\u00e4le 9 und 37 (20.40)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01097', '8.41095']],
      url: null
    }, {
      id: '15',
      title: 'Engelbert-Arnold-H\u00f6rsaal (11.10)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01064', '8.41281']],
      url: null
    }, {
      id: '16',
      title: 'Redtenbacher-H\u00f6rsaal (10.91)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00992', '8.41283']],
      url: null
    }, {
      id: '17',
      title: 'NTI-H\u00f6rsaal (30.10)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01271', '8.40944']],
      url: null
    }, {
      id: '18',
      title: 'Engler-Bunte-H\u00f6rsaal (40.11)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01400', '8.41488']],
      url: null
    }, {
      id: '19',
      title: 'Messtechnik-H\u00f6rsaal (30.33)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01221', '8.41308']],
      url: null
    }, {
      id: '20',
      title: 'Gerthsen-H\u00f6rsaal (30.21)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01243', '8.41157']],
      url: null
    }, {
      id: '21',
      title: 'Daimler-H\u00f6rsaal (10.21)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00946', '8.41343']],
      url: null
    }, {
      id: '22',
      title: 'Audimax (30.95)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01271', '8.41573']],
      url: null
    }, {
      id: '23',
      title: 'Rehbock-H\u00f6rsaal (10.81)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01013', '8.41555']],
      url: null
    }, {
      id: '24',
      title: 'Gaede-H\u00f6rsaal (30.22)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01256', '8.41039']],
      url: null
    }, {
      id: '25',
      title: 'Physik-H\u00f6rs\u00e4le (30.22)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01242', '8.41071']],
      url: null
    }, {
      id: '26',
      title: 'Hertz-H\u00f6rsaal (10.11)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00948', '8.41292']],
      url: null
    }, {
      id: '27',
      title: 'Nusselt-H\u00f6rsaal (10.23)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00946', '8.41390']],
      url: null
    }, {
      id: '28',
      title: 'Chemie-H\u00f6rs\u00e4le I-III (30.41)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01260', '8.41385']],
      url: null
    }, {
      id: '29',
      title: 'Criegee-H\u00f6rsaal (30.41)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01260', '8.41385']],
      url: null
    }, {
      id: '30',
      title: 'Plank-H\u00f6rsaal (40.32)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.01457', '8.41734']],
      url: null
    }, {
      id: '31',
      title: '10.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.00945', '8.41346']],
      url: null
    }, {
      id: '32',
      title: '10.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.00937', '8.41428']],
      url: null
    }, {
      id: '33',
      title: '40.27',
      category: 'building',
      aliasList: [],
      positionList: [['49.01437', '8.41573']],
      url: null
    }, {
      id: '34',
      title: '30.43',
      category: 'building',
      aliasList: [],
      positionList: [['49.01237', '8.41451']],
      url: null
    }, {
      id: '35',
      title: '40.18',
      category: 'building',
      aliasList: [],
      positionList: [['49.01550', '8.41484']],
      url: null
    }, {
      id: '36',
      title: '40.19',
      category: 'building',
      aliasList: [],
      positionList: [['49.01448', '8.41445']],
      url: null
    }, {
      id: '37',
      title: '20.54',
      category: 'building',
      aliasList: [],
      positionList: [['49.01195', '8.40923']],
      url: null
    }, {
      id: '38',
      title: '20.52',
      category: 'building',
      aliasList: [],
      positionList: [['49.01224', '8.40929']],
      url: null
    }, {
      id: '39',
      title: '20.53',
      category: 'building',
      aliasList: [],
      positionList: [['49.01210', '8.40938']],
      url: null
    }, {
      id: '40',
      title: '20.50',
      category: 'building',
      aliasList: [],
      positionList: [['49.01240', '8.40874']],
      url: null
    }, {
      id: '41',
      title: '20.51',
      category: 'building',
      aliasList: [],
      positionList: [['49.01230', '8.40904']],
      url: null
    }, {
      id: '42',
      title: '40.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.01400', '8.41489']],
      url: null
    }, {
      id: '43',
      title: '40.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.01436', '8.41486']],
      url: null
    }, {
      id: '44',
      title: '40.13',
      category: 'building',
      aliasList: [],
      positionList: [['49.01471', '8.41518']],
      url: null
    }, {
      id: '45',
      title: '40.14',
      category: 'building',
      aliasList: [],
      positionList: [['49.01478', '8.41453']],
      url: null
    }, {
      id: '46',
      title: '30.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.01312', '8.40918']],
      url: null
    }, {
      id: '47',
      title: '30.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.01310', '8.40976']],
      url: null
    }, {
      id: '48',
      title: '30.10',
      category: 'building',
      aliasList: [],
      positionList: [['49.01288', '8.40949']],
      url: null
    }, {
      id: '49',
      title: '08.03',
      category: 'building',
      aliasList: [],
      positionList: [['49.00761', '8.40435']],
      url: null
    }, {
      id: '50',
      title: '30.96',
      category: 'building',
      aliasList: [],
      positionList: [['49.01300', '8.41598']],
      url: null
    }, {
      id: '51',
      title: '30.95',
      category: 'building',
      aliasList: [],
      positionList: [['49.01268', '8.41569']],
      url: null
    }, {
      id: '52',
      title: '30.93',
      category: 'building',
      aliasList: [],
      positionList: [['49.01273', '8.41333']],
      url: null
    }, {
      id: '53',
      title: '30.29',
      category: 'building',
      aliasList: [],
      positionList: [['49.01192', '8.41168']],
      url: null
    }, {
      id: '54',
      title: '30.91',
      category: 'building',
      aliasList: [],
      positionList: [['49.01328', '8.41374']],
      url: null
    }, {
      id: '55',
      title: '30.90',
      category: 'building',
      aliasList: [],
      positionList: [['49.01328', '8.41426']],
      url: null
    }, {
      id: '56',
      title: '40.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01407', '8.41570']],
      url: null
    }, {
      id: '57',
      title: '11.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.01017', '8.41184']],
      url: null
    }, {
      id: '58',
      title: '10.95',
      category: 'building',
      aliasList: [],
      positionList: [['49.01006', '8.41419']],
      url: null
    }, {
      id: '59',
      title: '10.94',
      category: 'building',
      aliasList: [],
      positionList: [['49.01029', '8.41380']],
      url: null
    }, {
      id: '60',
      title: '01.52',
      category: 'building',
      aliasList: [],
      positionList: [['49.01307', '8.40839']],
      url: null
    }, {
      id: '61',
      title: '01.51',
      category: 'building',
      aliasList: [],
      positionList: [['49.01320', '8.40771']],
      url: null
    }, {
      id: '62',
      title: '10.50',
      category: 'building',
      aliasList: [],
      positionList: [['49.00951', '8.41695']],
      url: null
    }, {
      id: '63',
      title: '06.46',
      category: 'building',
      aliasList: [],
      positionList: [['49.02042', '8.36752']],
      url: null
    }, {
      id: '64',
      title: '30.80',
      category: 'building',
      aliasList: [],
      positionList: [['49.01327', '8.41485']],
      url: null
    }, {
      id: '65',
      title: '30.81',
      category: 'building',
      aliasList: [],
      positionList: [['49.01109', '8.41467']],
      url: null
    }, {
      id: '66',
      title: '30.82',
      category: 'building',
      aliasList: [],
      positionList: [['49.01112', '8.41530']],
      url: null
    }, {
      id: '67',
      title: '50.38',
      category: 'building',
      aliasList: [],
      positionList: [['49.01491', '8.42104']],
      url: null
    }, {
      id: '68',
      title: '40.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.01416', '8.41626']],
      url: null
    }, {
      id: '69',
      title: '50.10',
      category: 'building',
      aliasList: [],
      positionList: [['49.01286', '8.41928']],
      url: null
    }, {
      id: '70',
      title: '50.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.01289', '8.41956']],
      url: null
    }, {
      id: '71',
      title: '40.22',
      category: 'building',
      aliasList: [],
      positionList: [['49.01405', '8.41610']],
      url: null
    }, {
      id: '72',
      title: '40.16',
      category: 'building',
      aliasList: [],
      positionList: [['49.01530', '8.41501']],
      url: null
    }, {
      id: '73',
      title: '20.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.01257', '8.40664']],
      url: null
    }, {
      id: '74',
      title: '10.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.00924', '8.41664']],
      url: null
    }, {
      id: '76',
      title: '30.79',
      category: 'building',
      aliasList: [],
      positionList: [['49.01330', '8.41711']],
      url: null
    }, {
      id: '77',
      title: '05.20',
      category: 'building',
      aliasList: [],
      positionList: [['49.00900', '8.40958']],
      url: null
    }, {
      id: '78',
      title: '30.70',
      category: 'building',
      aliasList: [],
      positionList: [['49.01300', '8.41709']],
      url: null
    }, {
      id: '79',
      title: '40.15',
      category: 'building',
      aliasList: [],
      positionList: [['49.01508', '8.41500']],
      url: null
    }, {
      id: '80',
      title: '10.62',
      category: 'building',
      aliasList: [],
      positionList: [['49.01055', '8.41748']],
      url: null
    }, {
      id: '81',
      title: '40.17',
      category: 'building',
      aliasList: [],
      positionList: [['49.01546', '8.41451']],
      url: null
    }, {
      id: '82',
      title: '30.46',
      category: 'building',
      aliasList: [],
      positionList: [['49.01168', '8.41384']],
      url: null
    }, {
      id: '83',
      title: '50.24',
      category: 'building',
      aliasList: [],
      positionList: [['49.01009', '8.41938']],
      url: null
    }, {
      id: '84',
      title: '40.24',
      category: 'building',
      aliasList: [],
      positionList: [['49.01428', '8.41624']],
      url: null
    }, {
      id: '85',
      title: '02.95',
      category: 'building',
      aliasList: [],
      positionList: [['49.01066', '8.40254']],
      url: null
    }, {
      id: '86',
      title: '40.26',
      category: 'building',
      aliasList: [],
      positionList: [['49.01450', '8.41598']],
      url: null
    }, {
      id: '87',
      title: '20.14',
      category: 'building',
      aliasList: [],
      positionList: [['49.01196', '8.40750']],
      url: null
    }, {
      id: '88',
      title: '40.43',
      category: 'building',
      aliasList: [],
      positionList: [['49.01653', '8.41585']],
      url: null
    }, {
      id: '89',
      title: '40.41',
      category: 'building',
      aliasList: [],
      positionList: [['49.01795', '8.41420']],
      url: null
    }, {
      id: '90',
      title: '40.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.01579', '8.41638']],
      url: null
    }, {
      id: '91',
      title: '30.60',
      category: 'building',
      aliasList: [],
      positionList: [['49.01233', '8.41669']],
      url: null
    }, {
      id: '92',
      title: '30.61',
      category: 'building',
      aliasList: [],
      positionList: [['49.01229', '8.41729']],
      url: null
    }, {
      id: '93',
      title: '10.70',
      category: 'building',
      aliasList: [],
      positionList: [['49.00986', '8.41629']],
      url: null
    }, {
      id: '94',
      title: '50.33',
      category: 'building',
      aliasList: [],
      positionList: [['49.01459', '8.42128']],
      url: null
    }, {
      id: '95',
      title: '50.32',
      category: 'building',
      aliasList: [],
      positionList: [['49.01395', '8.42157']],
      url: null
    }, {
      id: '96',
      title: '50.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.01413', '8.42077']],
      url: null
    }, {
      id: '97',
      title: '50.36',
      category: 'building',
      aliasList: [],
      positionList: [['49.01493', '8.41964']],
      url: null
    }, {
      id: '98',
      title: '50.35',
      category: 'building',
      aliasList: [],
      positionList: [['49.01469', '8.42042']],
      url: null
    }, {
      id: '99',
      title: '50.34',
      category: 'building',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: null
    }, {
      id: '100',
      title: '06.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.02243', '8.36692']],
      url: null
    }, {
      id: '101',
      title: '06.32',
      category: 'building',
      aliasList: [],
      positionList: [['49.02208', '8.36703']],
      url: null
    }, {
      id: '102',
      title: '06.33',
      category: 'building',
      aliasList: [],
      positionList: [['49.02144', '8.36683']],
      url: null
    }, {
      id: '103',
      title: '06.34',
      category: 'building',
      aliasList: [],
      positionList: [['49.02103', '8.36681']],
      url: null
    }, {
      id: '104',
      title: '06.35',
      category: 'building',
      aliasList: [],
      positionList: [['49.02179', '8.36620']],
      url: null
    }, {
      id: '105',
      title: '06.36',
      category: 'building',
      aliasList: [],
      positionList: [['49.02109', '8.36599']],
      url: null
    }, {
      id: '106',
      title: '06.38',
      category: 'building',
      aliasList: [],
      positionList: [['49.02180', '8.36824']],
      url: null
    }, {
      id: '107',
      title: '11.10',
      category: 'building',
      aliasList: [],
      positionList: [['49.01078', '8.41282']],
      url: null
    }, {
      id: '108',
      title: '11.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.01105', '8.41261']],
      url: null
    }, {
      id: '109',
      title: '07.07',
      category: 'building',
      aliasList: [],
      positionList: [['49.01313', '8.42424']],
      url: null
    }, {
      id: '110',
      title: '07.01',
      category: 'building',
      aliasList: [],
      positionList: [['49.01450', '8.42461']],
      url: null
    }, {
      id: '111',
      title: '10.64',
      category: 'building',
      aliasList: [],
      positionList: [['49.00993', '8.41741']],
      url: null
    }, {
      id: '112',
      title: '20.13',
      category: 'building',
      aliasList: [],
      positionList: [['49.01227', '8.40707']],
      url: null
    }, {
      id: '113',
      title: '20.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.01296', '8.40643']],
      url: null
    }, {
      id: '114',
      title: '10.61',
      category: 'building',
      aliasList: [],
      positionList: [['49.01016', '8.41743']],
      url: null
    }, {
      id: '115',
      title: '07.08',
      category: 'building',
      aliasList: [],
      positionList: [['49.01367', '8.42420']],
      url: null
    }, {
      id: '116',
      title: '10.63',
      category: 'building',
      aliasList: [],
      positionList: [['49.01048', '8.41707']],
      url: null
    }, {
      id: '117',
      title: '40.29',
      category: 'building',
      aliasList: [],
      positionList: [['49.01468', '8.41574']],
      url: null
    }, {
      id: '118',
      title: '50.25',
      category: 'building',
      aliasList: [],
      positionList: [['49.00995', '8.41917']],
      url: null
    }, {
      id: '119',
      title: '50.20',
      category: 'building',
      aliasList: [],
      positionList: [['49.01025', '8.41890']],
      url: null
    }, {
      id: '120',
      title: '50.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01087', '8.41900']],
      url: null
    }, {
      id: '121',
      title: '50.22',
      category: 'building',
      aliasList: [],
      positionList: [['49.01082', '8.41959']],
      url: null
    }, {
      id: '122',
      title: '50.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.01007', '8.41969']],
      url: null
    }, {
      id: '123',
      title: '30.51',
      category: 'building',
      aliasList: [],
      positionList: [['49.01099', '8.41697']],
      url: null
    }, {
      id: '124',
      title: '30.50',
      category: 'building',
      aliasList: [],
      positionList: [['49.01134', '8.41642']],
      url: null
    }, {
      id: '125',
      title: '05.01',
      category: 'building',
      aliasList: [],
      positionList: [['49.00904', '8.40535']],
      url: null
    }, {
      id: '126',
      title: '70.26',
      category: 'building',
      aliasList: [],
      positionList: [['49.02274', '8.43366']],
      url: null
    }, {
      id: '127',
      title: '20.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01210', '8.40808']],
      url: null
    }, {
      id: '128',
      title: '20.20',
      category: 'building',
      aliasList: [],
      positionList: [['49.01242', '8.40811']],
      url: null
    }, {
      id: '129',
      title: '11.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.01074', '8.41339']],
      url: null
    }, {
      id: '130',
      title: '11.22',
      category: 'building',
      aliasList: [],
      positionList: [['49.01059', '8.41404']],
      url: null
    }, {
      id: '131',
      title: '11.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01079', '8.41405']],
      url: null
    }, {
      id: '132',
      title: '11.20',
      category: 'building',
      aliasList: [],
      positionList: [['49.01103', '8.41217']],
      url: null
    }, {
      id: '133',
      title: '06.45',
      category: 'building',
      aliasList: [],
      positionList: [['49.02033', '8.36771']],
      url: null
    }, {
      id: '134',
      title: '30.45',
      category: 'building',
      aliasList: [],
      positionList: [['49.01140', '8.41380']],
      url: null
    }, {
      id: '135',
      title: '06.47',
      category: 'building',
      aliasList: [],
      positionList: [['49.02025', '8.36731']],
      url: null
    }, {
      id: '136',
      title: '07.30',
      category: 'building',
      aliasList: [],
      positionList: [['49.01414', '8.42578']],
      url: null
    }, {
      id: '137',
      title: '06.41',
      category: 'building',
      aliasList: [],
      positionList: [['49.02129', '8.36819']],
      url: null
    }, {
      id: '138',
      title: '06.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.02165', '8.36765']],
      url: null
    }, {
      id: '139',
      title: '30.42',
      category: 'building',
      aliasList: [],
      positionList: [['49.01296', '8.41423']],
      url: null
    }, {
      id: '140',
      title: '06.42',
      category: 'building',
      aliasList: [],
      positionList: [['49.02056', '8.36729']],
      url: null
    }, {
      id: '141',
      title: '10.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.00933', '8.41223']],
      url: null
    }, {
      id: '142',
      title: '40.28',
      category: 'building',
      aliasList: [],
      positionList: [['49.01509', '8.41592']],
      url: null
    }, {
      id: '143',
      title: '10.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.00960', '8.41179']],
      url: null
    }, {
      id: '144',
      title: '40.32',
      category: 'building',
      aliasList: [],
      positionList: [['49.01453', '8.41733']],
      url: null
    }, {
      id: '145',
      title: '40.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.01405', '8.41722']],
      url: null
    }, {
      id: '146',
      title: '20.30',
      category: 'building',
      aliasList: [],
      positionList: [['49.01114', '8.40930']],
      url: null
    }, {
      id: '147',
      title: '30.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.01242', '8.41319']],
      url: null
    }, {
      id: '148',
      title: '40.33',
      category: 'building',
      aliasList: [],
      positionList: [['49.01495', '8.41716']],
      url: null
    }, {
      id: '149',
      title: '30.33',
      category: 'building',
      aliasList: [],
      positionList: [['49.01226', '8.41320']],
      url: null
    }, {
      id: '150',
      title: '30.32',
      category: 'building',
      aliasList: [],
      positionList: [['49.01239', '8.41271']],
      url: null
    }, {
      id: '151',
      title: '30.35',
      category: 'building',
      aliasList: [],
      positionList: [['49.01198', '8.41267']],
      url: null
    }, {
      id: '152',
      title: '30.34',
      category: 'building',
      aliasList: [],
      positionList: [['49.01171', '8.41303']],
      url: null
    }, {
      id: '153',
      title: '30.36',
      category: 'building',
      aliasList: [],
      positionList: [['49.01193', '8.41234']],
      url: null
    }, {
      id: '154',
      title: '07.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01272', '8.42534']],
      url: null
    }, {
      id: '155',
      title: '70.13',
      category: 'building',
      aliasList: [],
      positionList: [['49.02333', '8.43157']],
      url: null
    }, {
      id: '156',
      title: '50.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.01547', '8.42037']],
      url: null
    }, {
      id: '157',
      title: '50.41',
      category: 'building',
      aliasList: [],
      positionList: [['49.01569', '8.41946']],
      url: null
    }, {
      id: '158',
      title: '70.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.02429', '8.43218']],
      url: null
    }, {
      id: '159',
      title: '01.96',
      category: 'building',
      aliasList: [],
      positionList: [['49.00890', '8.41130']],
      url: null
    }, {
      id: '160',
      title: '01.94',
      category: 'building',
      aliasList: [],
      positionList: [['49.00814', '8.40898']],
      url: null
    }, {
      id: '161',
      title: '01.93',
      category: 'building',
      aliasList: [],
      positionList: [['49.00843', '8.40875']],
      url: null
    }, {
      id: '162',
      title: '01.92',
      category: 'building',
      aliasList: [],
      positionList: [['49.00845', '8.40891']],
      url: null
    }, {
      id: '163',
      title: 'Ostendorfhaus',
      category: 'building',
      aliasList: [],
      positionList: [['49.01419', '8.38327']],
      url: null
    }, {
      id: '164',
      title: '10.91',
      category: 'building',
      aliasList: [],
      positionList: [['49.00990', '8.41322']],
      url: null
    }, {
      id: '165',
      title: '10.93',
      category: 'building',
      aliasList: [],
      positionList: [['49.01033', '8.41342']],
      url: null
    }, {
      id: '166',
      title: '10.92',
      category: 'building',
      aliasList: [],
      positionList: [['49.01033', '8.41301']],
      url: null
    }, {
      id: '167',
      title: '01.13',
      category: 'building',
      aliasList: [],
      positionList: [['49.01192', '8.41690']],
      url: null
    }, {
      id: '168',
      title: '01.12',
      category: 'building',
      aliasList: [],
      positionList: [['49.01153', '8.41739']],
      url: null
    }, {
      id: '169',
      title: '10.96',
      category: 'building',
      aliasList: [],
      positionList: [['49.00983', '8.41417']],
      url: null
    }, {
      id: '170',
      title: '10.86',
      category: 'building',
      aliasList: [],
      positionList: [['49.01011', '8.41636']],
      url: null
    }, {
      id: '171',
      title: '70.22',
      category: 'building',
      aliasList: [],
      positionList: [['49.02390', '8.43246']],
      url: null
    }, {
      id: '172',
      title: '70.16',
      category: 'building',
      aliasList: [],
      positionList: [['49.02186', '8.43111']],
      url: null
    }, {
      id: '173',
      title: '10.34',
      category: 'building',
      aliasList: [],
      positionList: [['49.00921', '8.41600']],
      url: null
    }, {
      id: '174',
      title: '10.33',
      category: 'building',
      aliasList: [],
      positionList: [['49.00937', '8.41602']],
      url: null
    }, {
      id: '175',
      title: '10.32',
      category: 'building',
      aliasList: [],
      positionList: [['49.00923', '8.41502']],
      url: null
    }, {
      id: '176',
      title: '10.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.00920', '8.41372']],
      url: null
    }, {
      id: '177',
      title: '10.30',
      category: 'building',
      aliasList: [],
      positionList: [['49.00958', '8.41548']],
      url: null
    }, {
      id: '178',
      title: '70.14',
      category: 'building',
      aliasList: [],
      positionList: [['49.02393', '8.43245']],
      url: null
    }, {
      id: '179',
      title: '30.22',
      category: 'building',
      aliasList: [],
      positionList: [['49.01257', '8.41068']],
      url: null
    }, {
      id: '180',
      title: '30.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: null
    }, {
      id: '181',
      title: '06.60',
      category: 'building',
      aliasList: [],
      positionList: [['49.02355', '8.36775']],
      url: null
    }, {
      id: '182',
      title: '20.44',
      category: 'building',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: null
    }, {
      id: '183',
      title: '30.24',
      category: 'building',
      aliasList: [],
      positionList: [['49.01318', '8.41076']],
      url: null
    }, {
      id: '184',
      title: '30.25',
      category: 'building',
      aliasList: [],
      positionList: [['49.01288', '8.41168']],
      url: null
    }, {
      id: '185',
      title: '40.04',
      category: 'building',
      aliasList: [],
      positionList: [['49.01519', '8.41450']],
      url: null
    }, {
      id: '186',
      title: '40.02',
      category: 'building',
      aliasList: [],
      positionList: [['49.01429', '8.41519']],
      url: null
    }, {
      id: '187',
      title: '30.44',
      category: 'building',
      aliasList: [],
      positionList: [['49.01187', '8.41442']],
      url: null
    }, {
      id: '188',
      title: '07.31',
      category: 'building',
      aliasList: [],
      positionList: [['49.01420', '8.42627']],
      url: null
    }, {
      id: '189',
      title: '10.82',
      category: 'building',
      aliasList: [],
      positionList: [['49.01022', '8.41555']],
      url: null
    }, {
      id: '190',
      title: '10.83',
      category: 'building',
      aliasList: [],
      positionList: [['49.01065', '8.41518']],
      url: null
    }, {
      id: '191',
      title: '70.11',
      category: 'building',
      aliasList: [],
      positionList: [['49.02379', '8.43115']],
      url: null
    }, {
      id: '192',
      title: '10.81',
      category: 'building',
      aliasList: [],
      positionList: [['49.01027', '8.41561']],
      url: null
    }, {
      id: '193',
      title: '11.30',
      category: 'building',
      aliasList: ['adolf-w\u00fcrth-geb\u00e4ude', 'senatssaal'],
      positionList: [['49.01027', '8.41252']],
      url: null
    }, {
      id: '194',
      title: '10.87',
      category: 'building',
      aliasList: [],
      positionList: [['49.01009', '8.41686']],
      url: null
    }, {
      id: '195',
      title: '10.84',
      category: 'building',
      aliasList: [],
      positionList: [['49.01068', '8.41578']],
      url: null
    }, {
      id: '196',
      title: '10.85',
      category: 'building',
      aliasList: [],
      positionList: [['49.01065', '8.41646']],
      url: null
    }, {
      id: '197',
      title: '40.25',
      category: 'building',
      aliasList: [],
      positionList: [['49.01451', '8.41627']],
      url: null
    }, {
      id: '198',
      title: '10.88',
      category: 'building',
      aliasList: [],
      positionList: [['49.01031', '8.41638']],
      url: null
    }, {
      id: '199',
      title: '10.89',
      category: 'building',
      aliasList: [],
      positionList: [['49.01037', '8.41656']],
      url: null
    }, {
      id: '200',
      title: '30.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01239', '8.41158']],
      url: null
    }, {
      id: '201',
      title: '30.41',
      category: 'building',
      aliasList: [],
      positionList: [['49.01234', '8.41383']],
      url: null
    }, {
      id: '202',
      title: '101',
      category: 'building',
      aliasList: [],
      positionList: [['49.09136', '8.42731']],
      url: null
    }, {
      id: '203',
      title: '103',
      category: 'building',
      aliasList: [],
      positionList: [['49.09184', '8.42696']],
      url: null
    }, {
      id: '204',
      title: '121',
      category: 'building',
      aliasList: [],
      positionList: [['49.09200', '8.42816']],
      url: null
    }, {
      id: '205',
      title: '122',
      category: 'building',
      aliasList: [],
      positionList: [['49.09211', '8.42778']],
      url: null
    }, {
      id: '206',
      title: '123',
      category: 'building',
      aliasList: [],
      positionList: [['49.09233', '8.42800']],
      url: null
    }, {
      id: '207',
      title: '124',
      category: 'building',
      aliasList: [],
      positionList: [['49.09248', '8.42721']],
      url: null
    }, {
      id: '208',
      title: '126',
      category: 'building',
      aliasList: [],
      positionList: [['49.09272', '8.42677']],
      url: null
    }, {
      id: '209',
      title: '131',
      category: 'building',
      aliasList: [],
      positionList: [['49.09232', '8.42664']],
      url: null
    }, {
      id: '210',
      title: '141',
      category: 'building',
      aliasList: [],
      positionList: [['49.09297', '8.42784']],
      url: null
    }, {
      id: '211',
      title: '142',
      category: 'building',
      aliasList: [],
      positionList: [['49.09339', '8.42757']],
      url: null
    }, {
      id: '212',
      title: '144',
      category: 'building',
      aliasList: [],
      positionList: [['49.09368', '8.42773']],
      url: null
    }, {
      id: '213',
      title: '151',
      category: 'building',
      aliasList: [],
      positionList: [['49.09457', '8.42548']],
      url: null
    }, {
      id: '214',
      title: '152',
      category: 'building',
      aliasList: [],
      positionList: [['49.09402', '8.42629']],
      url: null
    }, {
      id: '215',
      title: '210',
      category: 'building',
      aliasList: [],
      positionList: [['49.09148', '8.42960']],
      url: null
    }, {
      id: '216',
      title: '211',
      category: 'building',
      aliasList: [],
      positionList: [['49.09105', '8.42943']],
      url: null
    }, {
      id: '217',
      title: '212',
      category: 'building',
      aliasList: [],
      positionList: [['49.09096', '8.42980']],
      url: null
    }, {
      id: '218',
      title: '213',
      category: 'building',
      aliasList: [],
      positionList: [['49.09221', '8.43097']],
      url: null
    }, {
      id: '219',
      title: '220',
      category: 'building',
      aliasList: [],
      positionList: [['49.09221', '8.43097']],
      url: null
    }, {
      id: '220',
      title: '221',
      category: 'building',
      aliasList: [],
      positionList: [['49.09200', '8.42897']],
      url: null
    }, {
      id: '221',
      title: '222',
      category: 'building',
      aliasList: [],
      positionList: [['49.09180', '8.42934']],
      url: null
    }, {
      id: '222',
      title: '223',
      category: 'building',
      aliasList: [],
      positionList: [['49.09191', '8.42966']],
      url: null
    }, {
      id: '223',
      title: '224',
      category: 'building',
      aliasList: [],
      positionList: [['49.09169', '8.43002']],
      url: null
    }, {
      id: '224',
      title: '225',
      category: 'building',
      aliasList: [],
      positionList: [['49.09194', '8.43033']],
      url: null
    }, {
      id: '225',
      title: '226',
      category: 'building',
      aliasList: [],
      positionList: [['49.09161', '8.43049']],
      url: null
    }, {
      id: '226',
      title: '227',
      category: 'building',
      aliasList: [],
      positionList: [['49.09178', '8.43090']],
      url: null
    }, {
      id: '227',
      title: '228',
      category: 'building',
      aliasList: [],
      positionList: [['49.09146', '8.43139']],
      url: null
    }, {
      id: '228',
      title: '230',
      category: 'building',
      aliasList: [],
      positionList: [['49.09285', '8.43058']],
      url: null
    }, {
      id: '229',
      title: '231',
      category: 'building',
      aliasList: [],
      positionList: [['49.09154', '8.43301']],
      url: null
    }, {
      id: '230',
      title: '232',
      category: 'building',
      aliasList: [],
      positionList: [['49.09112', '8.43287']],
      url: null
    }, {
      id: '231',
      title: '234',
      category: 'building',
      aliasList: [],
      positionList: [['49.09112', '8.43473']],
      url: null
    }, {
      id: '232',
      title: '235',
      category: 'building',
      aliasList: [],
      positionList: [['49.09317', '8.43264']],
      url: null
    }, {
      id: '233',
      title: '237',
      category: 'building',
      aliasList: [],
      positionList: [['49.09295', '8.43260']],
      url: null
    }, {
      id: '234',
      title: '238',
      category: 'building',
      aliasList: [],
      positionList: [['49.09338', '8.43117']],
      url: null
    }, {
      id: '235',
      title: '239',
      category: 'building',
      aliasList: [],
      positionList: [['49.09322', '8.43152']],
      url: null
    }, {
      id: '236',
      title: '240',
      category: 'building',
      aliasList: [],
      positionList: [['49.09341', '8.43050']],
      url: null
    }, {
      id: '237',
      title: '241',
      category: 'building',
      aliasList: [],
      positionList: [['49.09275', '8.43012']],
      url: null
    }, {
      id: '238',
      title: '242',
      category: 'building',
      aliasList: [],
      positionList: [['49.09253', '8.43087']],
      url: null
    }, {
      id: '239',
      title: '243',
      category: 'building',
      aliasList: [],
      positionList: [['49.09249', '8.43135']],
      url: null
    }, {
      id: '240',
      title: '244',
      category: 'building',
      aliasList: [],
      positionList: [['49.09280', '8.43137']],
      url: null
    }, {
      id: '241',
      title: '245',
      category: 'building',
      aliasList: [],
      positionList: [['49.09279', '8.43224']],
      url: null
    }, {
      id: '242',
      title: '246',
      category: 'building',
      aliasList: [],
      positionList: [['49.09200', '8.43170']],
      url: null
    }, {
      id: '243',
      title: '247',
      category: 'building',
      aliasList: [],
      positionList: [['49.09296', '8.43104']],
      url: null
    }, {
      id: '244',
      title: '248',
      category: 'building',
      aliasList: [],
      positionList: [['49.09287', '8.43164']],
      url: null
    }, {
      id: '245',
      title: '249',
      category: 'building',
      aliasList: [],
      positionList: [['49.09280', '8.43132']],
      url: null
    }, {
      id: '246',
      title: '250',
      category: 'building',
      aliasList: [],
      positionList: [['49.09217', '8.43178']],
      url: null
    }, {
      id: '247',
      title: '251',
      category: 'building',
      aliasList: [],
      positionList: [['49.09245', '8.43313']],
      url: null
    }, {
      id: '248',
      title: '252',
      category: 'building',
      aliasList: [],
      positionList: [['49.09193', '8.43407']],
      url: null
    }, {
      id: '249',
      title: '253',
      category: 'building',
      aliasList: [],
      positionList: [['49.09156', '8.43476']],
      url: null
    }, {
      id: '250',
      title: '254',
      category: 'building',
      aliasList: [],
      positionList: [['49.09161', '8.43514']],
      url: null
    }, {
      id: '251',
      title: '255',
      category: 'building',
      aliasList: [],
      positionList: [['49.09219', '8.43513']],
      url: null
    }, {
      id: '252',
      title: '256',
      category: 'building',
      aliasList: [],
      positionList: [['49.09200', '8.43320']],
      url: null
    }, {
      id: '253',
      title: '257',
      category: 'building',
      aliasList: [],
      positionList: [['49.09235', '8.43350']],
      url: null
    }, {
      id: '254',
      title: '258',
      category: 'building',
      aliasList: [],
      positionList: [['49.09205', '8.43578']],
      url: null
    }, {
      id: '255',
      title: '259',
      category: 'building',
      aliasList: [],
      positionList: [['49.09200', '8.43505']],
      url: null
    }, {
      id: '256',
      title: '260',
      category: 'building',
      aliasList: [],
      positionList: [['49.09241', '8.43435']],
      url: null
    }, {
      id: '257',
      title: '261',
      category: 'building',
      aliasList: [],
      positionList: [['49.09258', '8.43343']],
      url: null
    }, {
      id: '258',
      title: '272',
      category: 'building',
      aliasList: [],
      positionList: [['49.09271', '8.43541']],
      url: null
    }, {
      id: '259',
      title: '273',
      category: 'building',
      aliasList: [],
      positionList: [['49.09266', '8.43471']],
      url: null
    }, {
      id: '260',
      title: '274',
      category: 'building',
      aliasList: [],
      positionList: [['49.09261', '8.43509']],
      url: null
    }, {
      id: '261',
      title: '276',
      category: 'building',
      aliasList: [],
      positionList: [['49.09287', '8.43409']],
      url: null
    }, {
      id: '262',
      title: '277',
      category: 'building',
      aliasList: [],
      positionList: [['49.09252', '8.43572']],
      url: null
    }, {
      id: '263',
      title: '278',
      category: 'building',
      aliasList: [],
      positionList: [['49.09248', '8.43603']],
      url: null
    }, {
      id: '264',
      title: '300',
      category: 'building',
      aliasList: [],
      positionList: [['49.09425', '8.42918']],
      url: null
    }, {
      id: '265',
      title: '301',
      category: 'building',
      aliasList: [],
      positionList: [['49.09452', '8.42965']],
      url: null
    }, {
      id: '266',
      title: '302',
      category: 'building',
      aliasList: [],
      positionList: [['49.09467', '8.42911']],
      url: null
    }, {
      id: '267',
      title: '303',
      category: 'building',
      aliasList: [],
      positionList: [['49.09467', '8.42857']],
      url: null
    }, {
      id: '268',
      title: '304',
      category: 'building',
      aliasList: [],
      positionList: [['49.09482', '8.42743']],
      url: null
    }, {
      id: '269',
      title: '305',
      category: 'building',
      aliasList: [],
      positionList: [['49.09500', '8.42795']],
      url: null
    }, {
      id: '270',
      title: '306',
      category: 'building',
      aliasList: [],
      positionList: [['49.09501', '8.42721']],
      url: null
    }, {
      id: '271',
      title: '307',
      category: 'building',
      aliasList: [],
      positionList: [['49.09435', '8.42883']],
      url: null
    }, {
      id: '272',
      title: '308',
      category: 'building',
      aliasList: [],
      positionList: [['49.09468', '8.42935']],
      url: null
    }, {
      id: '273',
      title: '309',
      category: 'building',
      aliasList: [],
      positionList: [['49.09411', '8.42910']],
      url: null
    }, {
      id: '274',
      title: '310',
      category: 'building',
      aliasList: [],
      positionList: [['49.09466', '8.42887']],
      url: null
    }, {
      id: '275',
      title: '311',
      category: 'building',
      aliasList: [],
      positionList: [['49.09517', '8.42633']],
      url: null
    }, {
      id: '276',
      title: '312',
      category: 'building',
      aliasList: [],
      positionList: [['49.09467', '8.42700']],
      url: null
    }, {
      id: '277',
      title: '313',
      category: 'building',
      aliasList: [],
      positionList: [['49.09436', '8.42755']],
      url: null
    }, {
      id: '278',
      title: '314',
      category: 'building',
      aliasList: [],
      positionList: [['49.09479', '8.42934']],
      url: null
    }, {
      id: '279',
      title: '315',
      category: 'building',
      aliasList: [],
      positionList: [['49.09585', '8.42655']],
      url: null
    }, {
      id: '280',
      title: '316',
      category: 'building',
      aliasList: [],
      positionList: [['49.09475', '8.42656']],
      url: null
    }, {
      id: '281',
      title: '317',
      category: 'building',
      aliasList: [],
      positionList: [['49.09445', '8.42718']],
      url: null
    }, {
      id: '282',
      title: '318',
      category: 'building',
      aliasList: [],
      positionList: [['49.09481', '8.42923']],
      url: null
    }, {
      id: '283',
      title: '320',
      category: 'building',
      aliasList: [],
      positionList: [['49.09562', '8.42907']],
      url: null
    }, {
      id: '284',
      title: '321',
      category: 'building',
      aliasList: [],
      positionList: [['49.09514', '8.42984']],
      url: null
    }, {
      id: '285',
      title: '322',
      category: 'building',
      aliasList: [],
      positionList: [['49.09541', '8.42940']],
      url: null
    }, {
      id: '286',
      title: '324',
      category: 'building',
      aliasList: [],
      positionList: [['49.09593', '8.42827']],
      url: null
    }, {
      id: '287',
      title: '326',
      category: 'building',
      aliasList: [],
      positionList: [['49.09538', '8.42897']],
      url: null
    }, {
      id: '288',
      title: '327',
      category: 'building',
      aliasList: [],
      positionList: [['49.09576', '8.42928']],
      url: null
    }, {
      id: '289',
      title: '328',
      category: 'building',
      aliasList: [],
      positionList: [['49.09511', '8.42943']],
      url: null
    }, {
      id: '290',
      title: '329',
      category: 'building',
      aliasList: [],
      positionList: [['49.09598', '8.42891']],
      url: null
    }, {
      id: '291',
      title: '330',
      category: 'building',
      aliasList: [],
      positionList: [['49.09630', '8.42593']],
      url: null
    }, {
      id: '292',
      title: '332',
      category: 'building',
      aliasList: [],
      positionList: [['49.09616', '8.42633']],
      url: null
    }, {
      id: '293',
      title: '333',
      category: 'building',
      aliasList: [],
      positionList: [['49.09600', '8.42612']],
      url: null
    }, {
      id: '294',
      title: '341',
      category: 'building',
      aliasList: [],
      positionList: [['49.09673', '8.43008']],
      url: null
    }, {
      id: '295',
      title: '343',
      category: 'building',
      aliasList: [],
      positionList: [['49.09657', '8.42936']],
      url: null
    }, {
      id: '296',
      title: '345',
      category: 'building',
      aliasList: [],
      positionList: [['49.09624', '8.42980']],
      url: null
    }, {
      id: '297',
      title: '348',
      category: 'building',
      aliasList: [],
      positionList: [['49.09675', '8.42863']],
      url: null
    }, {
      id: '298',
      title: '349',
      category: 'building',
      aliasList: [],
      positionList: [['49.09694', '8.42919']],
      url: null
    }, {
      id: '299',
      title: '351',
      category: 'building',
      aliasList: [],
      positionList: [['49.09730', '8.42677']],
      url: null
    }, {
      id: '300',
      title: '352',
      category: 'building',
      aliasList: [],
      positionList: [['49.09704', '8.42750']],
      url: null
    }, {
      id: '301',
      title: '353',
      category: 'building',
      aliasList: [],
      positionList: [['49.09673', '8.42636']],
      url: null
    }, {
      id: '302',
      title: '400',
      category: 'building',
      aliasList: [],
      positionList: [['49.09290', '8.43620']],
      url: null
    }, {
      id: '303',
      title: '401',
      category: 'building',
      aliasList: [],
      positionList: [['49.09415', '8.43074']],
      url: null
    }, {
      id: '304',
      title: '402',
      category: 'building',
      aliasList: [],
      positionList: [['49.09439', '8.43120']],
      url: null
    }, {
      id: '305',
      title: '403',
      category: 'building',
      aliasList: [],
      positionList: [['49.09412', '8.43185']],
      url: null
    }, {
      id: '306',
      title: '404',
      category: 'building',
      aliasList: [],
      positionList: [['49.09377', '8.43215']],
      url: null
    }, {
      id: '307',
      title: '405',
      category: 'building',
      aliasList: [],
      positionList: [['49.09352', '8.43211']],
      url: null
    }, {
      id: '308',
      title: '406',
      category: 'building',
      aliasList: [],
      positionList: [['49.09403', '8.43307']],
      url: null
    }, {
      id: '309',
      title: '407',
      category: 'building',
      aliasList: [],
      positionList: [['49.09374', '8.43178']],
      url: null
    }, {
      id: '310',
      title: '408',
      category: 'building',
      aliasList: [],
      positionList: [['49.09394', '8.43248']],
      url: null
    }, {
      id: '311',
      title: '409',
      category: 'building',
      aliasList: [],
      positionList: [['49.09408', '8.43340']],
      url: null
    }, {
      id: '312',
      title: '410',
      category: 'building',
      aliasList: [],
      positionList: [['49.09364', '8.43377']],
      url: null
    }, {
      id: '313',
      title: '411',
      category: 'building',
      aliasList: [],
      positionList: [['49.09412', '8.43251']],
      url: null
    }, {
      id: '314',
      title: '412',
      category: 'building',
      aliasList: [],
      positionList: [['49.09288', '8.43591']],
      url: null
    }, {
      id: '315',
      title: '413',
      category: 'building',
      aliasList: [],
      positionList: [['49.09306', '8.43534']],
      url: null
    }, {
      id: '316',
      title: '414',
      category: 'building',
      aliasList: [],
      positionList: [['49.09348', '8.43479']],
      url: null
    }, {
      id: '317',
      title: '415',
      category: 'building',
      aliasList: [],
      positionList: [['49.09339', '8.43537']],
      url: null
    }, {
      id: '318',
      title: '416',
      category: 'building',
      aliasList: [],
      positionList: [['49.09327', '8.43397']],
      url: null
    }, {
      id: '319',
      title: '417',
      category: 'building',
      aliasList: [],
      positionList: [['49.09350', '8.43441']],
      url: null
    }, {
      id: '320',
      title: '418',
      category: 'building',
      aliasList: [],
      positionList: [['49.09388', '8.43420']],
      url: null
    }, {
      id: '321',
      title: '419',
      category: 'building',
      aliasList: [],
      positionList: [['49.09378', '8.43489']],
      url: null
    }, {
      id: '322',
      title: '420',
      category: 'building',
      aliasList: [],
      positionList: [['49.09359', '8.43577']],
      url: null
    }, {
      id: '323',
      title: '421',
      category: 'building',
      aliasList: [],
      positionList: [['49.09502', '8.43111']],
      url: null
    }, {
      id: '324',
      title: '422',
      category: 'building',
      aliasList: [],
      positionList: [['49.09490', '8.43163']],
      url: null
    }, {
      id: '325',
      title: '423',
      category: 'building',
      aliasList: [],
      positionList: [['49.09498', '8.43216']],
      url: null
    }, {
      id: '326',
      title: '424',
      category: 'building',
      aliasList: [],
      positionList: [['49.09477', '8.43271']],
      url: null
    }, {
      id: '327',
      title: '425',
      category: 'building',
      aliasList: [],
      positionList: [['49.09482', '8.43335']],
      url: null
    }, {
      id: '328',
      title: '426',
      category: 'building',
      aliasList: [],
      positionList: [['49.09517', '8.43325']],
      url: null
    }, {
      id: '329',
      title: '427',
      category: 'building',
      aliasList: [],
      positionList: [['49.09493', '8.43261']],
      url: null
    }, {
      id: '330',
      title: '428',
      category: 'building',
      aliasList: [],
      positionList: [['49.09528', '8.43230']],
      url: null
    }, {
      id: '331',
      title: '429',
      category: 'building',
      aliasList: [],
      positionList: [['49.09533', '8.43181']],
      url: null
    }, {
      id: '332',
      title: '430',
      category: 'building',
      aliasList: [],
      positionList: [['49.09375', '8.43128']],
      url: null
    }, {
      id: '333',
      title: '432',
      category: 'building',
      aliasList: [],
      positionList: [['49.09434', '8.43436']],
      url: null
    }, {
      id: '334',
      title: '433',
      category: 'building',
      aliasList: [],
      positionList: [['49.09462', '8.43414']],
      url: null
    }, {
      id: '335',
      title: '434',
      category: 'building',
      aliasList: [],
      positionList: [['49.09520', '8.43298']],
      url: null
    }, {
      id: '336',
      title: '435',
      category: 'building',
      aliasList: [],
      positionList: [['49.09391', '8.43650']],
      url: null
    }, {
      id: '337',
      title: '436',
      category: 'building',
      aliasList: [],
      positionList: [['49.09485', '8.43473']],
      url: null
    }, {
      id: '338',
      title: '438',
      category: 'building',
      aliasList: [],
      positionList: [['49.09457', '8.43518']],
      url: null
    }, {
      id: '339',
      title: '439',
      category: 'building',
      aliasList: [],
      positionList: [['49.09409', '8.43590']],
      url: null
    }, {
      id: '340',
      title: '441',
      category: 'building',
      aliasList: [],
      positionList: [['49.09590', '8.43195']],
      url: null
    }, {
      id: '341',
      title: '442',
      category: 'building',
      aliasList: [],
      positionList: [['49.09589', '8.43237']],
      url: null
    }, {
      id: '342',
      title: '443',
      category: 'building',
      aliasList: [],
      positionList: [['49.09505', '8.43385']],
      url: null
    }, {
      id: '343',
      title: '444',
      category: 'building',
      aliasList: [],
      positionList: [['49.09421', '8.43485']],
      url: null
    }, {
      id: '344',
      title: '445',
      category: 'building',
      aliasList: [],
      positionList: [['49.09575', '8.43372']],
      url: null
    }, {
      id: '345',
      title: '446',
      category: 'building',
      aliasList: [],
      positionList: [['49.09434', '8.43605']],
      url: null
    }, {
      id: '346',
      title: '447',
      category: 'building',
      aliasList: [],
      positionList: [['49.09502', '8.43385']],
      url: null
    }, {
      id: '347',
      title: '448',
      category: 'building',
      aliasList: [],
      positionList: [['49.09452', '8.43666']],
      url: null
    }, {
      id: '348',
      title: '450',
      category: 'building',
      aliasList: [],
      positionList: [['49.09521', '8.43489']],
      url: null
    }, {
      id: '349',
      title: '451',
      category: 'building',
      aliasList: [],
      positionList: [['49.09554', '8.43457']],
      url: null
    }, {
      id: '350',
      title: '452',
      category: 'building',
      aliasList: [],
      positionList: [['49.09534', '8.43568']],
      url: null
    }, {
      id: '351',
      title: '453',
      category: 'building',
      aliasList: [],
      positionList: [['49.09533', '8.43658']],
      url: null
    }, {
      id: '352',
      title: '454',
      category: 'building',
      aliasList: [],
      positionList: [['49.09511', '8.43628']],
      url: null
    }, {
      id: '353',
      title: '456',
      category: 'building',
      aliasList: [],
      positionList: [['49.09536', '8.43621']],
      url: null
    }, {
      id: '354',
      title: '458',
      category: 'building',
      aliasList: [],
      positionList: [['49.09517', '8.43674']],
      url: null
    }, {
      id: '355',
      title: '459',
      category: 'building',
      aliasList: [],
      positionList: [['49.09500', '8.43640']],
      url: null
    }, {
      id: '356',
      title: '460',
      category: 'building',
      aliasList: [],
      positionList: [['49.09582', '8.43599']],
      url: null
    }, {
      id: '357',
      title: '461',
      category: 'building',
      aliasList: [],
      positionList: [['49.09568', '8.43643']],
      url: null
    }, {
      id: '358',
      title: '462',
      category: 'building',
      aliasList: [],
      positionList: [['49.09582', '8.43635']],
      url: null
    }, {
      id: '359',
      title: '464',
      category: 'building',
      aliasList: [],
      positionList: [['49.09553', '8.43643']],
      url: null
    }, {
      id: '360',
      title: '500',
      category: 'building',
      aliasList: [],
      positionList: [['49.09820', '8.42690']],
      url: null
    }, {
      id: '361',
      title: '510',
      category: 'building',
      aliasList: [],
      positionList: [['49.09796', '8.42789']],
      url: null
    }, {
      id: '362',
      title: '511',
      category: 'building',
      aliasList: [],
      positionList: [['49.09769', '8.42716']],
      url: null
    }, {
      id: '363',
      title: '512',
      category: 'building',
      aliasList: [],
      positionList: [['49.09858', '8.42767']],
      url: null
    }, {
      id: '364',
      title: '513',
      category: 'building',
      aliasList: [],
      positionList: [['49.09889', '8.42735']],
      url: null
    }, {
      id: '365',
      title: '514',
      category: 'building',
      aliasList: [],
      positionList: [['49.09904', '8.42685']],
      url: null
    }, {
      id: '366',
      title: '515',
      category: 'building',
      aliasList: [],
      positionList: [['49.09864', '8.42673']],
      url: null
    }, {
      id: '367',
      title: '516',
      category: 'building',
      aliasList: [],
      positionList: [['49.09874', '8.42807']],
      url: null
    }, {
      id: '368',
      title: '517',
      category: 'building',
      aliasList: [],
      positionList: [['49.09833', '8.42662']],
      url: null
    }, {
      id: '369',
      title: '518',
      category: 'building',
      aliasList: [],
      positionList: [['49.09792', '8.42698']],
      url: null
    }, {
      id: '370',
      title: '519',
      category: 'building',
      aliasList: [],
      positionList: [['49.09772', '8.42894']],
      url: null
    }, {
      id: '371',
      title: '521',
      category: 'building',
      aliasList: [],
      positionList: [['49.09839', '8.43092']],
      url: null
    }, {
      id: '372',
      title: '522',
      category: 'building',
      aliasList: [],
      positionList: [['49.09873', '8.43113']],
      url: null
    }, {
      id: '373',
      title: '523',
      category: 'building',
      aliasList: [],
      positionList: [['49.09885', '8.43061']],
      url: null
    }, {
      id: '374',
      title: '526',
      category: 'building',
      aliasList: [],
      positionList: [['49.09779', '8.43037']],
      url: null
    }, {
      id: '375',
      title: '527',
      category: 'building',
      aliasList: [],
      positionList: [['49.09864', '8.43008']],
      url: null
    }, {
      id: '376',
      title: '528',
      category: 'building',
      aliasList: [],
      positionList: [['49.09837', '8.42898']],
      url: null
    }, {
      id: '377',
      title: '532',
      category: 'building',
      aliasList: [],
      positionList: [['49.09899', '8.42796']],
      url: null
    }, {
      id: '378',
      title: '535',
      category: 'building',
      aliasList: [],
      positionList: [['49.09947', '8.42821']],
      url: null
    }, {
      id: '379',
      title: '536',
      category: 'building',
      aliasList: [],
      positionList: [['49.09970', '8.42721']],
      url: null
    }, {
      id: '380',
      title: '541',
      category: 'building',
      aliasList: [],
      positionList: [['49.09932', '8.43138']],
      url: null
    }, {
      id: '381',
      title: '542',
      category: 'building',
      aliasList: [],
      positionList: [['49.09936', '8.43100']],
      url: null
    }, {
      id: '382',
      title: '544',
      category: 'building',
      aliasList: [],
      positionList: [['49.09986', '8.43156']],
      url: null
    }, {
      id: '383',
      title: '545',
      category: 'building',
      aliasList: [],
      positionList: [['49.09978', '8.43055']],
      url: null
    }, {
      id: '384',
      title: '546',
      category: 'building',
      aliasList: [],
      positionList: [['49.10014', '8.43101']],
      url: null
    }, {
      id: '385',
      title: '547',
      category: 'building',
      aliasList: [],
      positionList: [['49.10026', '8.43040']],
      url: null
    }, {
      id: '386',
      title: '548',
      category: 'building',
      aliasList: [],
      positionList: [['49.10065', '8.43067']],
      url: null
    }, {
      id: '387',
      title: '549',
      category: 'building',
      aliasList: [],
      positionList: [['49.09959', '8.43003']],
      url: null
    }, {
      id: '388',
      title: '551',
      category: 'building',
      aliasList: [],
      positionList: [['49.09972', '8.42927']],
      url: null
    }, {
      id: '389',
      title: '553',
      category: 'building',
      aliasList: [],
      positionList: [['49.10038', '8.42969']],
      url: null
    }, {
      id: '390',
      title: '556',
      category: 'building',
      aliasList: [],
      positionList: [['49.10088', '8.43074']],
      url: null
    }, {
      id: '391',
      title: '559',
      category: 'building',
      aliasList: [],
      positionList: [['49.10104', '8.42978']],
      url: null
    }, {
      id: '392',
      title: '560',
      category: 'building',
      aliasList: [],
      positionList: [['49.10098', '8.43025']],
      url: null
    }, {
      id: '393',
      title: '561',
      category: 'building',
      aliasList: [],
      positionList: [['49.10005', '8.42877']],
      url: null
    }, {
      id: '394',
      title: '562',
      category: 'building',
      aliasList: [],
      positionList: [['49.10081', '8.42957']],
      url: null
    }, {
      id: '395',
      title: '563',
      category: 'building',
      aliasList: [],
      positionList: [['49.10012', '8.42834']],
      url: null
    }, {
      id: '396',
      title: '570',
      category: 'building',
      aliasList: [],
      positionList: [['49.10042', '8.42868']],
      url: null
    }, {
      id: '397',
      title: '571',
      category: 'building',
      aliasList: [],
      positionList: [['49.10083', '8.42881']],
      url: null
    }, {
      id: '398',
      title: '572',
      category: 'building',
      aliasList: [],
      positionList: [['49.10052', '8.42835']],
      url: null
    }, {
      id: '399',
      title: '573',
      category: 'building',
      aliasList: [],
      positionList: [['49.10057', '8.42793']],
      url: null
    }, {
      id: '400',
      title: '574',
      category: 'building',
      aliasList: [],
      positionList: [['49.10087', '8.42806']],
      url: null
    }, {
      id: '401',
      title: '575',
      category: 'building',
      aliasList: [],
      positionList: [['49.10114', '8.42769']],
      url: null
    }, {
      id: '402',
      title: '576',
      category: 'building',
      aliasList: [],
      positionList: [['49.10021', '8.42934']],
      url: null
    }, {
      id: '403',
      title: '577',
      category: 'building',
      aliasList: [],
      positionList: [['49.10063', '8.42949']],
      url: null
    }, {
      id: '404',
      title: '580',
      category: 'building',
      aliasList: [],
      positionList: [['49.09953', '8.43034']],
      url: null
    }, {
      id: '405',
      title: '581',
      category: 'building',
      aliasList: [],
      positionList: [['49.09942', '8.43029']],
      url: null
    }, {
      id: '406',
      title: '601',
      category: 'building',
      aliasList: [],
      positionList: [['49.09684', '8.43233']],
      url: null
    }, {
      id: '407',
      title: '602',
      category: 'building',
      aliasList: [],
      positionList: [['49.09674', '8.43296']],
      url: null
    }, {
      id: '408',
      title: '603',
      category: 'building',
      aliasList: [],
      positionList: [['49.09685', '8.43333']],
      url: null
    }, {
      id: '409',
      title: '604',
      category: 'building',
      aliasList: [],
      positionList: [['49.09653', '8.43412']],
      url: null
    }, {
      id: '410',
      title: '605',
      category: 'building',
      aliasList: [],
      positionList: [['49.09716', '8.43201']],
      url: null
    }, {
      id: '411',
      title: '606',
      category: 'building',
      aliasList: [],
      positionList: [['49.09729', '8.43239']],
      url: null
    }, {
      id: '412',
      title: '607',
      category: 'building',
      aliasList: [],
      positionList: [['49.09718', '8.43305']],
      url: null
    }, {
      id: '413',
      title: '608',
      category: 'building',
      aliasList: [],
      positionList: [['49.09702', '8.43454']],
      url: null
    }, {
      id: '414',
      title: '611',
      category: 'building',
      aliasList: [],
      positionList: [['49.09838', '8.43287']],
      url: null
    }, {
      id: '415',
      title: '614',
      category: 'building',
      aliasList: [],
      positionList: [['49.09870', '8.43206']],
      url: null
    }, {
      id: '416',
      title: '615',
      category: 'building',
      aliasList: [],
      positionList: [['49.09859', '8.43319']],
      url: null
    }, {
      id: '417',
      title: '618',
      category: 'building',
      aliasList: [],
      positionList: [['49.09805', '8.43475']],
      url: null
    }, {
      id: '418',
      title: '620',
      category: 'building',
      aliasList: [],
      positionList: [['49.09706', '8.43674']],
      url: null
    }, {
      id: '419',
      title: '622',
      category: 'building',
      aliasList: [],
      positionList: [['49.09685', '8.43675']],
      url: null
    }, {
      id: '420',
      title: '629',
      category: 'building',
      aliasList: [],
      positionList: [['49.09719', '8.43553']],
      url: null
    }, {
      id: '421',
      title: '630',
      category: 'building',
      aliasList: [],
      positionList: [['49.09691', '8.43555']],
      url: null
    }, {
      id: '422',
      title: '631',
      category: 'building',
      aliasList: [],
      positionList: [['49.09646', '8.43629']],
      url: null
    }, {
      id: '423',
      title: '633',
      category: 'building',
      aliasList: [],
      positionList: [['49.09678', '8.43503']],
      url: null
    }, {
      id: '424',
      title: '640',
      category: 'building',
      aliasList: [],
      positionList: [['49.09787', '8.43300']],
      url: null
    }, {
      id: '425',
      title: '648',
      category: 'building',
      aliasList: [],
      positionList: [['49.10083', '8.43467']],
      url: null
    }, {
      id: '426',
      title: '650',
      category: 'building',
      aliasList: [],
      positionList: [['49.09833', '8.43515']],
      url: null
    }, {
      id: '427',
      title: '660',
      category: 'building',
      aliasList: [],
      positionList: [['49.09899', '8.43461']],
      url: null
    }, {
      id: '428',
      title: '663',
      category: 'building',
      aliasList: [],
      positionList: [['49.09892', '8.43519']],
      url: null
    }, {
      id: '429',
      title: '670',
      category: 'building',
      aliasList: [],
      positionList: [['49.09945', '8.43485']],
      url: null
    }, {
      id: '430',
      title: '679',
      category: 'building',
      aliasList: [],
      positionList: [['49.10070', '8.43372']],
      url: null
    }, {
      id: '431',
      title: '681',
      category: 'building',
      aliasList: [],
      positionList: [['49.10050', '8.43398']],
      url: null
    }, {
      id: '432',
      title: '682',
      category: 'building',
      aliasList: [],
      positionList: [['49.10022', '8.43385']],
      url: null
    }, {
      id: '433',
      title: '686',
      category: 'building',
      aliasList: [],
      positionList: [['49.10120', '8.43414']],
      url: null
    }, {
      id: '434',
      title: '688',
      category: 'building',
      aliasList: [],
      positionList: [['49.10080', '8.43652']],
      url: null
    }, {
      id: '435',
      title: '689',
      category: 'building',
      aliasList: [],
      positionList: [['49.10069', '8.43632']],
      url: null
    }, {
      id: '436',
      title: '690',
      category: 'building',
      aliasList: [],
      positionList: [['49.10011', '8.43600']],
      url: null
    }, {
      id: '437',
      title: '691',
      category: 'building',
      aliasList: [],
      positionList: [['49.10021', '8.43523']],
      url: null
    }, {
      id: '438',
      title: '692',
      category: 'building',
      aliasList: [],
      positionList: [['49.09967', '8.43563']],
      url: null
    }, {
      id: '439',
      title: '693',
      category: 'building',
      aliasList: [],
      positionList: [['49.10050', '8.43593']],
      url: null
    }, {
      id: '440',
      title: '695',
      category: 'building',
      aliasList: [],
      positionList: [['49.09993', '8.43350']],
      url: null
    }, {
      id: '441',
      title: '696',
      category: 'building',
      aliasList: [],
      positionList: [['49.09970', '8.43405']],
      url: null
    }, {
      id: '442',
      title: '701',
      category: 'building',
      aliasList: [],
      positionList: [['49.10091', '8.43171']],
      url: null
    }, {
      id: '443',
      title: '702',
      category: 'building',
      aliasList: [],
      positionList: [['49.10141', '8.43149']],
      url: null
    }, {
      id: '444',
      title: '704',
      category: 'building',
      aliasList: [],
      positionList: [['49.10151', '8.43181']],
      url: null
    }, {
      id: '445',
      title: '705',
      category: 'building',
      aliasList: [],
      positionList: [['49.10149', '8.43237']],
      url: null
    }, {
      id: '446',
      title: '706',
      category: 'building',
      aliasList: [],
      positionList: [['49.10156', '8.43203']],
      url: null
    }, {
      id: '447',
      title: '707',
      category: 'building',
      aliasList: [],
      positionList: [['49.10174', '8.43143']],
      url: null
    }, {
      id: '448',
      title: '708',
      category: 'building',
      aliasList: [],
      positionList: [['49.10097', '8.43265']],
      url: null
    }, {
      id: '449',
      title: '712',
      category: 'building',
      aliasList: [],
      positionList: [['49.10182', '8.42906']],
      url: null
    }, {
      id: '450',
      title: '713',
      category: 'building',
      aliasList: [],
      positionList: [['49.10128', '8.43000']],
      url: null
    }, {
      id: '451',
      title: '714',
      category: 'building',
      aliasList: [],
      positionList: [['49.10151', '8.42858']],
      url: null
    }, {
      id: '452',
      title: '715',
      category: 'building',
      aliasList: [],
      positionList: [['49.10211', '8.42885']],
      url: null
    }, {
      id: '453',
      title: '716',
      category: 'building',
      aliasList: [],
      positionList: [['49.10223', '8.42905']],
      url: null
    }, {
      id: '454',
      title: '717',
      category: 'building',
      aliasList: [],
      positionList: [['49.10170', '8.43084']],
      url: null
    }, {
      id: '455',
      title: '718',
      category: 'building',
      aliasList: [],
      positionList: [['49.10253', '8.42817']],
      url: null
    }, {
      id: '456',
      title: '719',
      category: 'building',
      aliasList: [],
      positionList: [['49.10121', '8.43084']],
      url: null
    }, {
      id: '457',
      title: '721',
      category: 'building',
      aliasList: [],
      positionList: [['49.10281', '8.43261']],
      url: null
    }, {
      id: '458',
      title: '723',
      category: 'building',
      aliasList: [],
      positionList: [['49.10269', '8.43175']],
      url: null
    }, {
      id: '459',
      title: '724',
      category: 'building',
      aliasList: [],
      positionList: [['49.10293', '8.43197']],
      url: null
    }, {
      id: '460',
      title: '725',
      category: 'building',
      aliasList: [],
      positionList: [['49.10298', '8.43322']],
      url: null
    }, {
      id: '461',
      title: '726',
      category: 'building',
      aliasList: [],
      positionList: [['49.10227', '8.43219']],
      url: null
    }, {
      id: '462',
      title: '727',
      category: 'building',
      aliasList: [],
      positionList: [['49.10222', '8.43292']],
      url: null
    }, {
      id: '463',
      title: '729',
      category: 'building',
      aliasList: [],
      positionList: [['49.10265', '8.43313']],
      url: null
    }, {
      id: '464',
      title: '730',
      category: 'building',
      aliasList: [],
      positionList: [['49.10253', '8.43335']],
      url: null
    }, {
      id: '465',
      title: '732',
      category: 'building',
      aliasList: [],
      positionList: [['49.10215', '8.43241']],
      url: null
    }, {
      id: '466',
      title: '733',
      category: 'building',
      aliasList: [],
      positionList: [['49.10325', '8.43217']],
      url: null
    }, {
      id: '467',
      title: '736',
      category: 'building',
      aliasList: [],
      positionList: [['49.10199', '8.43207']],
      url: null
    }, {
      id: '468',
      title: '739',
      category: 'building',
      aliasList: [],
      positionList: [['49.10336', '8.43076']],
      url: null
    }, {
      id: '469',
      title: '740',
      category: 'building',
      aliasList: [],
      positionList: [['49.10241', '8.43079']],
      url: null
    }, {
      id: '470',
      title: '741',
      category: 'building',
      aliasList: [],
      positionList: [['49.10343', '8.42945']],
      url: null
    }, {
      id: '471',
      title: '742',
      category: 'building',
      aliasList: [],
      positionList: [['49.10333', '8.42982']],
      url: null
    }, {
      id: '472',
      title: '744',
      category: 'building',
      aliasList: [],
      positionList: [['49.10307', '8.42952']],
      url: null
    }, {
      id: '473',
      title: '745',
      category: 'building',
      aliasList: [],
      positionList: [['49.10317', '8.42991']],
      url: null
    }, {
      id: '474',
      title: '752',
      category: 'building',
      aliasList: [],
      positionList: [['49.10402', '8.42868']],
      url: null
    }, {
      id: '475',
      title: '754',
      category: 'building',
      aliasList: [],
      positionList: [['49.10291', '8.42841']],
      url: null
    }, {
      id: '476',
      title: '755',
      category: 'building',
      aliasList: [],
      positionList: [['49.10281', '8.42889']],
      url: null
    }, {
      id: '477',
      title: '757',
      category: 'building',
      aliasList: [],
      positionList: [['49.10365', '8.42979']],
      url: null
    }, {
      id: '478',
      title: '758',
      category: 'building',
      aliasList: [],
      positionList: [['49.10286', '8.42983']],
      url: null
    }, {
      id: '479',
      title: '760',
      category: 'building',
      aliasList: [],
      positionList: [['49.10358', '8.42899']],
      url: null
    }, {
      id: '480',
      title: '768',
      category: 'building',
      aliasList: [],
      positionList: [['49.10355', '8.42996']],
      url: null
    }, {
      id: '481',
      title: '769',
      category: 'building',
      aliasList: [],
      positionList: [['49.10337', '8.43009']],
      url: null
    }, {
      id: '482',
      title: '801',
      category: 'building',
      aliasList: [],
      positionList: [['49.10251', '8.43543']],
      url: null
    }, {
      id: '483',
      title: '802',
      category: 'building',
      aliasList: [],
      positionList: [['49.10227', '8.43649']],
      url: null
    }, {
      id: '484',
      title: '803',
      category: 'building',
      aliasList: [],
      positionList: [['49.10288', '8.43598']],
      url: null
    }, {
      id: '485',
      title: '804',
      category: 'building',
      aliasList: [],
      positionList: [['49.10289', '8.43672']],
      url: null
    }, {
      id: '486',
      title: '805',
      category: 'building',
      aliasList: [],
      positionList: [['49.10335', '8.43573']],
      url: null
    }, {
      id: '487',
      title: '806',
      category: 'building',
      aliasList: [],
      positionList: [['49.10340', '8.43683']],
      url: null
    }, {
      id: '488',
      title: '807',
      category: 'building',
      aliasList: [],
      positionList: [['49.10416', '8.43618']],
      url: null
    }, {
      id: '489',
      title: '808',
      category: 'building',
      aliasList: [],
      positionList: [['49.10307', '8.43500']],
      url: null
    }, {
      id: '490',
      title: '809',
      category: 'building',
      aliasList: [],
      positionList: [['49.10394', '8.43490']],
      url: null
    }, {
      id: '491',
      title: '901',
      category: 'building',
      aliasList: [],
      positionList: [['49.10423', '8.43250']],
      url: null
    }, {
      id: '492',
      title: '902',
      category: 'building',
      aliasList: [],
      positionList: [['49.10427', '8.43210']],
      url: null
    }, {
      id: '493',
      title: '903',
      category: 'building',
      aliasList: [],
      positionList: [['49.10443', '8.43258']],
      url: null
    }, {
      id: '494',
      title: '904',
      category: 'building',
      aliasList: [],
      positionList: [['49.10456', '8.43254']],
      url: null
    }, {
      id: '495',
      title: '905',
      category: 'building',
      aliasList: [],
      positionList: [['49.10475', '8.43260']],
      url: null
    }, {
      id: '496',
      title: '907',
      category: 'building',
      aliasList: [],
      positionList: [['49.10483', '8.43132']],
      url: null
    }, {
      id: '497',
      title: '908',
      category: 'building',
      aliasList: [],
      positionList: [['49.10494', '8.43104']],
      url: null
    }, {
      id: '498',
      title: '910',
      category: 'building',
      aliasList: [],
      positionList: [['49.10520', '8.43311']],
      url: null
    }, {
      id: '499',
      title: '911',
      category: 'building',
      aliasList: [],
      positionList: [['49.10482', '8.43337']],
      url: null
    }, {
      id: '500',
      title: '912',
      category: 'building',
      aliasList: [],
      positionList: [['49.10470', '8.43335']],
      url: null
    }, {
      id: '501',
      title: '913',
      category: 'building',
      aliasList: [],
      positionList: [['49.10452', '8.43357']],
      url: null
    }, {
      id: '502',
      title: '914',
      category: 'building',
      aliasList: [],
      positionList: [['49.10442', '8.43315']],
      url: null
    }, {
      id: '503',
      title: '915',
      category: 'building',
      aliasList: [],
      positionList: [['49.10407', '8.43320']],
      url: null
    }, {
      id: '504',
      title: '916',
      category: 'building',
      aliasList: [],
      positionList: [['49.10402', '8.43287']],
      url: null
    }, {
      id: '505',
      title: '917',
      category: 'building',
      aliasList: [],
      positionList: [['49.10381', '8.43260']],
      url: null
    }, {
      id: '506',
      title: '918',
      category: 'building',
      aliasList: [],
      positionList: [['49.10373', '8.43298']],
      url: null
    }, {
      id: '507',
      title: '920',
      category: 'building',
      aliasList: [],
      positionList: [['49.10424', '8.43141']],
      url: null
    }, {
      id: '508',
      title: '935',
      category: 'building',
      aliasList: [],
      positionList: [['49.10416', '8.42909']],
      url: null
    }, {
      id: '509',
      title: '937',
      category: 'building',
      aliasList: [],
      positionList: [['49.10299', '8.43101']],
      url: null
    }, {
      id: '510',
      title: '9607',
      category: 'building',
      aliasList: [],
      positionList: [['49.09860', '8.42964']],
      url: null
    }, {
      id: '511',
      title: '9608',
      category: 'building',
      aliasList: [],
      positionList: [['49.09908', '8.42986']],
      url: null
    }, {
      id: '512',
      title: '9611',
      category: 'building',
      aliasList: [],
      positionList: [['49.09727', '8.43456']],
      url: null
    }, {
      id: '513',
      title: '9612',
      category: 'building',
      aliasList: [],
      positionList: [['49.09726', '8.43480']],
      url: null
    }, {
      id: '514',
      title: '9627',
      category: 'building',
      aliasList: [],
      positionList: [['49.09516', '8.43268']],
      url: null
    }, {
      id: '515',
      title: '9637',
      category: 'building',
      aliasList: [],
      positionList: [['49.09913', '8.42732']],
      url: null
    }, {
      id: '516',
      title: '9642',
      category: 'building',
      aliasList: [],
      positionList: [['49.09641', '8.42843']],
      url: null
    }, {
      id: '517',
      title: '9644',
      category: 'building',
      aliasList: [],
      positionList: [['49.09613', '8.42701']],
      url: null
    }, {
      id: '518',
      title: '9645',
      category: 'building',
      aliasList: [],
      positionList: [['49.09925', '8.42758']],
      url: null
    }, {
      id: '519',
      title: '9652',
      category: 'building',
      aliasList: [],
      positionList: [['49.09540', '8.43536']],
      url: null
    }, {
      id: '520',
      title: '9653',
      category: 'building',
      aliasList: [],
      positionList: [['49.09515', '8.43509']],
      url: null
    }, {
      id: '521',
      title: '9657',
      category: 'building',
      aliasList: [],
      positionList: [['49.09972', '8.42981']],
      url: null
    }, {
      id: '522',
      title: '9670',
      category: 'building',
      aliasList: [],
      positionList: [['49.10268', '8.42953']],
      url: null
    }, {
      id: '523',
      title: 'B524',
      category: 'building',
      aliasList: [],
      positionList: [['49.09899', '8.42903']],
      url: null
    }, {
      id: '524',
      title: '223/1',
      category: 'building',
      aliasList: [],
      positionList: [['49.09210', '8.42962']],
      url: null
    }, {
      id: '525',
      title: '919 A',
      category: 'building',
      aliasList: [],
      positionList: [['49.10362', '8.43258']],
      url: null
    }, {
      id: '526',
      title: 'B568',
      category: 'building',
      aliasList: [],
      positionList: [['49.10068', '8.42836']],
      url: null
    }, {
      id: '527',
      title: '260/1',
      category: 'building',
      aliasList: [],
      positionList: [['49.09245', '8.43414']],
      url: null
    }, {
      id: '528',
      title: 'B934',
      category: 'building',
      aliasList: [],
      positionList: [['49.10375', '8.43120']],
      url: null
    }, {
      id: '529',
      title: 'B569',
      category: 'building',
      aliasList: [],
      positionList: [['49.10062', '8.42849']],
      url: null
    }, {
      id: '530',
      title: 'B254',
      category: 'building',
      aliasList: [],
      positionList: [['49.09188', '8.43565']],
      url: null
    }, {
      id: '531',
      title: 'B404',
      category: 'building',
      aliasList: [],
      positionList: [['49.09404', '8.43218']],
      url: null
    }, {
      id: '532',
      title: 'B402',
      category: 'building',
      aliasList: [],
      positionList: [['49.09410', '8.43129']],
      url: null
    }, {
      id: '533',
      title: '9633 B',
      category: 'building',
      aliasList: [],
      positionList: [['49.09333', '8.43613']],
      url: null
    }, {
      id: '534',
      title: '441/1',
      category: 'building',
      aliasList: [],
      positionList: [['49.09644', '8.43172']],
      url: null
    }, {
      id: '535',
      title: 'B131',
      category: 'building',
      aliasList: [],
      positionList: [['49.09184', '8.42620']],
      url: null
    }, {
      id: '536',
      title: '919 B',
      category: 'building',
      aliasList: [],
      positionList: [['49.10356', '8.43302']],
      url: null
    }, {
      id: '537',
      title: 'B621',
      category: 'building',
      aliasList: [],
      positionList: [['49.09701', '8.43633']],
      url: null
    }, {
      id: '538',
      title: '9633 A',
      category: 'building',
      aliasList: [],
      positionList: [['49.09320', '8.43592']],
      url: null
    }, {
      id: '539',
      title: 'B523',
      category: 'building',
      aliasList: [],
      positionList: [['49.09903', '8.43074']],
      url: null
    }, {
      id: '540',
      title: 'B252',
      category: 'building',
      aliasList: [],
      positionList: [['49.09181', '8.43317']],
      url: null
    }, {
      id: '541',
      title: '406/2',
      category: 'building',
      aliasList: [],
      positionList: [['49.09409', '8.43305']],
      url: null
    }, {
      id: '542',
      title: '401 A',
      category: 'building',
      aliasList: [],
      positionList: [['49.09430', '8.43075']],
      url: null
    }, {
      id: '543',
      title: 'Technische Thermodynamik und K\u00e4ltetechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01453', '8.41733']],
      url: null
    }, {
      id: '544',
      title: 'Nanotechnologie (INT), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09786', '8.43289']],
      url: 'http://www.int.kit.edu'
    }, {
      id: '545',
      title: 'Prozessrechentechnik, Automation und Robotik (IPR), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://rob.ipr.kit.edu'
    }, {
      id: '546',
      title: 'Kunst- und Baugeschichte (IKB), Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: 'http://www.arch.kit.edu/institute/ikb.php'
    }, {
      id: '547',
      title: 'Hydromechanik, Institut f\u00fcr (IFH)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01027', '8.41561']],
      url: 'http://www.ifh.kit.edu'
    }, {
      id: '548',
      title: 'Bodenmechanik und Felsmechanik (IBF) , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01407', '8.41570']],
      url: 'http://ibf.webarchiv.kit.edu'
    }, {
      id: '549',
      title: 'Physikalische Chemie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01206', '8.41444']],
      url: 'http://www.ipc.kit.edu'
    }, {
      id: '550',
      title: 'Deutsch-Franz\u00f6sisches Institut f\u00fcr Umweltforschung (DFIU)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02144', '8.36683']],
      url: 'http://www.dfiu.kit.edu'
    }, {
      id: '551',
      title: 'Produktionstechnik (wbk), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01493', '8.41964']],
      url: 'http://www.wbk.kit.edu'
    }, {
      id: '552',
      title: 'Regelungs- und Steuerungssysteme, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01103', '8.41217']],
      url: 'http://www.irs.kit.edu/'
    }, {
      id: '554',
      title: 'Thermische Str\u00f6mungsmaschinen, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00990', '8.41322'], ['49.01253', '8.41661']],
      url: 'http://www.its.kit.edu'
    }, {
      id: '555',
      title: 'Funktionelle Grenzfl\u00e4chen (IFG), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09637', '8.42575']],
      url: 'http://www.ifg.kit.edu'
    }, {
      id: '556',
      title: 'Experimentelle Teilchenphysik (ETP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.etp.kit.edu/index.php'
    }, {
      id: '557',
      title: 'Institut f\u00fcr Biologische und Chemische Systeme (IBCS), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09496', '8.42757']],
      url: 'http://www.itg.kit.edu/'
    }, {
      id: '558',
      title: 'Engler-Bunte-Institut Bereich Verbrennungstechnik (EBI-VBT)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01400', '8.41489']],
      url: 'http://www.vbt.uni-karlsruhe.de'
    }, {
      id: '559',
      title: 'Technische Thermodynamik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00990', '8.41322']],
      url: 'http://www.itt.kit.edu'
    }, {
      id: '560',
      title: 'Biomedizinische Technik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01226', '8.41320']],
      url: 'http://www.ibt.kit.edu'
    }, {
      id: '561',
      title: 'Fahrzeugsystemtechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02189', '8.42923']],
      url: 'http://www.fast.kit.edu'
    }, {
      id: '562',
      title: 'Mikrostrukturtechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09449', '8.42954']],
      url: 'http://www.imt.kit.edu'
    }, {
      id: '563',
      title: 'Geophysikalisches Institut (GPI)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02109', '8.36599']],
      url: 'http://www.gpi.kit.edu'
    }, {
      id: '565',
      title: 'Programmstrukturen und Datenorganisation (IPD), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://www.informatik.kit.edu/257.php'
    }, {
      id: '567',
      title: 'Angewandte Informatik und Formale Beschreibungsverfahren (AIFB), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00894', '8.40949']],
      url: 'http://www.aifb.kit.edu/web/Hauptseite'
    }, {
      id: '568',
      title: 'Analysis, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01125', '8.40903']],
      url: 'http://www.math.kit.edu/iana'
    }, {
      id: '569',
      title: 'Wasser und Gew\u00e4sserentwicklung, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01065', '8.41518']],
      url: 'http://www.iwg.kit.edu/'
    }, {
      id: '570',
      title: 'Meteorologie und Klimaforschung, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.imk.kit.edu'
    }, {
      id: '571',
      title: 'Photonik und Quantenelektronik (IPQ), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01288', '8.40949']],
      url: 'http://www.ipq.kit.edu'
    }, {
      id: '572',
      title: 'Bio- und Lebensmitteltechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01415', '8.42662']],
      url: 'http://lvt.blt.kit.edu'
    }, {
      id: '574',
      title: 'Mechanische Verfahrenstechnik und Mechanik (MVM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01413', '8.42077']],
      url: 'http://www.mvm.kit.edu'
    }, {
      id: '576',
      title: 'Baustatik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00951', '8.41695']],
      url: 'http://www.ibs.kit.edu'
    }, {
      id: '577',
      title: 'Anthropomatik und Robotik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://www.informatik.kit.edu/1323.php'
    }, {
      id: '578',
      title: 'Geod\u00e4tisches Institut (GIK)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: 'http://www.gik.kit.edu'
    }, {
      id: '579',
      title: 'Hochfrequenztechnik und Elektronik (IHE), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01288', '8.40949']],
      url: 'http://www.ihe.kit.edu/'
    }, {
      id: '580',
      title: 'Entwerfen, Kunst und Theorie (EKUT), Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: 'http://www.arch.kit.edu/institute/ekut.php'
    }, {
      id: '581',
      title: 'Technikzuk\u00fcnfte - Philosophie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01257', '8.40664'], ['49.00948', '8.39325']],
      url: 'http://www.philosophie.kit.edu/'
    }, {
      id: '582',
      title: 'Karlsruhe Service Research Institute (KSRI)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00914', '8.40940']],
      url: 'http://www.ksri.kit.edu/'
    }, {
      id: '583',
      title: 'Sport und Sportwissenschaft, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01579', '8.41638']],
      url: 'http://www.sport.kit.edu/'
    }, {
      id: '584',
      title: 'Theoretische Elektrotechnik und Systemoptimierung (ITE), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01226', '8.41320']],
      url: 'http://www.ite.kit.edu/'
    }, {
      id: '585',
      title: 'Angewandte Physik, Institut f\u00fcr (AP)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.aph.kit.edu'
    }, {
      id: '586',
      title: 'Technik der Informationsverarbeitung (ITIV), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01288', '8.40949']],
      url: 'http://www.itiv.kit.edu/'
    }, {
      id: '587',
      title: 'Str\u00f6mungsmechanik (ISTM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00937', '8.41428']],
      url: 'http://www.istm.kit.edu'
    }, {
      id: '588',
      title: 'Technische Physik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09373', '8.43394']],
      url: 'http://www.itep.kit.edu'
    }, {
      id: '589',
      title: 'Regionalwissenschaft (IfR) , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00951', '8.41695']],
      url: 'http://www.ifr.kit.edu/'
    }, {
      id: '590',
      title: 'Mikroverfahrenstechnik (IMVT) , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09714', '8.43186']],
      url: 'http://www.imvt.kit.edu'
    }, {
      id: '591',
      title: 'Stra\u00dfen- und Eisenbahnwesen, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01027', '8.41561']],
      url: 'http://www.ise.kit.edu/'
    }, {
      id: '592',
      title: 'Technologie und Management im Baubetrieb (TMB) , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01413', '8.42077']],
      url: 'http://www.tmb.kit.edu'
    }, {
      id: '593',
      title: 'Biologische Grenzfl\u00e4chen (IBG), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09685', '8.43205']],
      url: 'http://www.ibg.kit.edu'
    }, {
      id: '594',
      title: 'Botanisches Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01246', '8.41443']],
      url: 'http://www.botanik.kit.edu/'
    }, {
      id: '595',
      title: 'Bio- und Lebensmitteltechnik, Bereich IV: Molekulare Aufarbeitung von Bioprodukten , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01547', '8.42037']],
      url: 'http://mab.blt.kit.edu'
    }, {
      id: '596',
      title: 'Theoretische Informatik (ITI), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://www.informatik.kit.edu/259.php'
    }, {
      id: '597',
      title: 'Thermische Verfahrenstechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00990', '8.41322']],
      url: 'http://www.tvt.kit.edu/'
    }, {
      id: '598',
      title: 'Institut f\u00fcr Wirtschaftsinformatik und Marketing (IISM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01210', '8.40808']],
      url: 'http://www.iism.kit.edu/'
    }, {
      id: '599',
      title: 'Algebra und Geometrie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01104', '8.40909']],
      url: 'http://www.math.kit.edu/iag'
    }, {
      id: '600',
      title: 'Entwerfen von Stadt und Landschaft (IESL), Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00951', '8.41695']],
      url: 'http://www.arch.kit.edu/institute/iesl.php'
    }, {
      id: '601',
      title: 'Finanzwirtschaft, Banken und Versicherungen (FBV), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01298', '8.37536']],
      url: 'http://www.fbv.kit.edu/'
    }, {
      id: '604',
      title: 'Lichttechnisches Institut (LTI)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01171', '8.41303']],
      url: 'http://www.lti.kit.edu'
    }, {
      id: '605',
      title: 'Berufsp\u00e4dagogik und Allgemeine P\u00e4dagogik (IBAP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02129', '8.36819']],
      url: 'http://www.ibap.kit.edu'
    }, {
      id: '606',
      title: 'Engler-Bunte-Institut, Bereich I, Chemische Energietr\u00e4ger - Brennstofftechnologie (EBI-CEB)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01400', '8.41489']],
      url: 'http://ceb.ebi.kit.edu'
    }, {
      id: '607',
      title: 'Angewandte Biowissenschaften (IAB), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01569', '8.41946']],
      url: 'http://www.iab.kit.edu/'
    }, {
      id: '609',
      title: 'Technikzuk\u00fcnfte - Geschichte, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00948', '8.39327']],
      url: 'http://www.geschichte.kit.edu/'
    }, {
      id: '610',
      title: 'Kernphysik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09435', '8.43073']],
      url: 'http://www.ikp.kit.edu'
    }, {
      id: '611',
      title: 'Telecooperation Office (TecO)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01304', '8.42419']],
      url: null
    }, {
      id: '612',
      title: 'Automation und Angewandte Informatik (IAI), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09589', '8.43363']],
      url: 'https://www.iai.kit.edu/index.php'
    }, {
      id: '613',
      title: 'Informationsmanagement im Ingenieurwesen (IMI) , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01242', '8.40811']],
      url: 'https://www.imi.kit.edu/'
    }, {
      id: '614',
      title: 'Thermische Energietechnik und Sicherheit (ITES), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09354', '8.43506']],
      url: 'http://www.iket.kit.edu'
    }, {
      id: '615',
      title: 'Interfakultatives Institut f\u00fcr Anwendungen der Informatik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.iiai.kit.edu/'
    }, {
      id: '616',
      title: 'Nachrichtentechnik , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00904', '8.40535']],
      url: 'https://www.cel.kit.edu'
    }, {
      id: '617',
      title: 'Hochleistungsimpuls- und Mikrowellentechnik (IHM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09505', '8.43078']],
      url: 'http://www.ihm.kit.edu'
    }, {
      id: '618',
      title: 'Zentrum f\u00fcr Angewandte Kulturwissenschaft und Studium Generale (ZAK)',
      category: 'institute',
      aliasList: ['zak'],
      positionList: [['49.00517', '8.41177']],
      url: 'http://www.zak.kit.edu'
    }, {
      id: '619',
      title: 'Versuchsanstalt f\u00fcr Stahl, Holz und Steine',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00986', '8.41629']],
      url: 'http://stahl.vaka.kit.edu'
    }, {
      id: '620',
      title: 'Telematik (ITM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01213', '8.40812']],
      url: 'https://telematics.tm.kit.edu'
    }, {
      id: '621',
      title: 'Technische Chemie (ITC), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09414', '8.43161']],
      url: 'http://www.itc.kit.edu'
    }, {
      id: '622',
      title: 'Katalyseforschung und -Technologie (IKFT), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.10259', '8.43291']],
      url: 'http://www.ikft.kit.edu/'
    }, {
      id: '623',
      title: 'Volkswirtschaftslehre, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00897', '8.41109']],
      url: 'http://www.econ.kit.edu/'
    }, {
      id: '625',
      title: 'Mess- und Regelungstechnik MRT, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01453', '8.41733']],
      url: 'http://www.mrt.kit.edu/'
    }, {
      id: '626',
      title: 'Industrielle Informationstechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02179', '8.36620']],
      url: 'http://www.iiit.kit.edu'
    }, {
      id: '628',
      title: 'Geographie und Geo\u00f6kologie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00951', '8.41695']],
      url: 'http://www.ifgg.kit.edu/'
    }, {
      id: '629',
      title: 'Physikalisches Institut (PI)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.phi.kit.edu'
    }, {
      id: '630',
      title: 'Angewandte Geowissenschaften, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01547', '8.42037']],
      url: 'http://www.agw.kit.edu'
    }, {
      id: '632',
      title: 'Engler-Bunte-Institut, Bereich Wasserchemie',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01400', '8.41489']],
      url: 'https://wasserchemie.ebi.kit.edu'
    }, {
      id: '633',
      title: 'Mikro- und Nanoelektronische Systeme (IMS), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02129', '8.36819']],
      url: 'http://www.ims.kit.edu'
    }, {
      id: '637',
      title: 'Angewandte und Numerische Mathematik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01104', '8.40903']],
      url: 'http://www.math.kit.edu/ianm'
    }, {
      id: '638',
      title: 'Mechanik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00958', '8.41548']],
      url: 'http://www.ifm.kit.edu'
    }, {
      id: '639',
      title: 'Zentrum f\u00fcr Angewandte Rechtswissenschaft (ZAR)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01386', '8.42415']],
      url: 'http://www.zar.kit.edu/'
    }, {
      id: '640',
      title: 'Massivbau und Baustofftechnologie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01413', '8.42077']],
      url: 'http://www.imb.kit.edu'
    }, {
      id: '641',
      title: 'Neutronenphysik und Reaktortechnik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09846', '8.43076']],
      url: 'http://www.inr.kit.edu'
    }, {
      id: '642',
      title: 'Organische Chemie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01296', '8.41423']],
      url: 'http://www.ioc.kit.edu/'
    }, {
      id: '643',
      title: 'Angewandte Thermofluidik (IATF), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01386', '8.42415']],
      url: 'https://www.ifrt.kit.edu'
    }, {
      id: '644',
      title: 'Nukleare Entsorgung (INE), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.10143', '8.42875']],
      url: 'http://www.ine.kit.edu'
    }, {
      id: '645',
      title: 'Theoretische Teilchenphysik (TTP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.ttp.kit.edu/'
    }, {
      id: '646',
      title: 'Theoretische Informatik, Arbeitsgruppe Kryptographie und Sicherheit (IKS), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://crypto.iti.kit.edu/?id=iti-crypto&L=0'
    }, {
      id: '647',
      title: 'Arbeitswissenschaft und Betriebsorganisation, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01468', '8.41574']],
      url: 'http://www.ifab.kit.edu/'
    }, {
      id: '648',
      title: 'Theoretische Physik (TP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.42907']],
      url: 'https://www.itp.kit.edu/index.de.shtml'
    }, {
      id: '649',
      title: 'Festk\u00f6rperphysik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09462', '8.43306']],
      url: 'http://www.ifp.kit.edu/'
    }, {
      id: '650',
      title: 'Zoologisches Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01237', '8.41451']],
      url: 'http://www.zoo.kit.edu'
    }, {
      id: '651',
      title: 'Chemische Verfahrenstechnik (CVT), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01187', '8.41442']],
      url: null
    }, {
      id: '652',
      title: 'Stochastik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01105', '8.40903']],
      url: 'http://www.math.kit.edu/stoch/de'
    }, {
      id: '653',
      title: 'Prozessdatenverarbeitung und Elektronik (IPE), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09237', '8.43075']],
      url: 'http://www.ipe.kit.edu/'
    }, {
      id: '654',
      title: 'Photogrammetrie und Fernerkundung (IPF), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: 'http://www.ipf.kit.edu/'
    }, {
      id: '655',
      title: 'Elektroenergiesysteme und Hochspannungstechnik , Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01193', '8.41234']],
      url: 'http://www.ieh.kit.edu/'
    }, {
      id: '656',
      title: 'Industriebetriebslehre und Industrielle Produktion (IIP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02144', '8.36683']],
      url: 'http://www.iip.kit.edu'
    }, {
      id: '657',
      title: 'Produktentwicklung (IPEK), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00937', '8.41428']],
      url: 'http://www.ipek.kit.edu'
    }, {
      id: '658',
      title: 'Technische Chemie und Polymerchemie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01079', '8.41405']],
      url: 'http://www.itcp.kit.ed'
    }, {
      id: '659',
      title: 'Anorganische Chemie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01140', '8.41380']],
      url: 'http://www.aoc.kit.edu'
    }, {
      id: '661',
      title: 'Theoretische Festk\u00f6rperphysik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01293', '8.41110']],
      url: 'http://www.tfp.kit.edu/'
    }, {
      id: '663',
      title: 'Entwerfen und Bautechnik, Institut',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01111', '8.41109']],
      url: 'http://www.arch.kit.edu/institute/ieb.php'
    }, {
      id: '664',
      title: 'Entrepreneurship, Technologie-Management und Innovation (EnTechnon), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00906', '8.41030']],
      url: 'https://www.entechnon.kit.edu'
    }, {
      id: '666',
      title: 'Technische Informatik (ITEC), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01389', '8.41951']],
      url: 'http://www.informatik.kit.edu/260.php'
    }, {
      id: '667',
      title: 'Elektrotechnisches Institut (ETI)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01078', '8.41282']],
      url: 'http://www.eti.kit.edu'
    }, {
      id: '668',
      title: 'Photonenforschung und Synchrotronstrahlung (IPS), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09599', '8.42888']],
      url: 'http://www.ips.kit.edu/index.php'
    }, {
      id: '670',
      title: 'Technische Mechanik, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00937', '8.41428']],
      url: 'http://www.itm.kit.edu'
    }, {
      id: '671',
      title: 'Verkehrswesen (IfV), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00958', '8.41548']],
      url: 'https://www.ifv.kit.edu/'
    }, {
      id: '672',
      title: 'Technikfolgenabsch\u00e4tzung und Systemanalyse (ITAS), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01119', '8.39534']],
      url: 'http://www.itas.kit.edu'
    }, {
      id: '673',
      title: 'F\u00f6rdertechnik und Logistiksysteme, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01491', '8.42104']],
      url: 'http://www.ifl.kit.edu/'
    }, {
      id: '674',
      title: 'Kolbenmaschinen, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.02186', '8.43111']],
      url: 'http://www.ifkm.kit.edu/'
    }, {
      id: '675',
      title: 'KITA nanos (Campus Nord)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09088', '8.43081']],
      url: null
    }, {
      id: '676',
      title: 'Pressestelle (SEK)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00943', '8.41195']],
      url: 'http://www.sek.kit.edu/presse.php'
    }, {
      id: '677',
      title: 'Studienkolleg f\u00fcr ausl\u00e4ndische Studierende (STK)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01031', '8.41878']],
      url: 'http://www.stk.kit.edu'
    }, {
      id: '678',
      title: 'Forschungsf\u00f6rderung (FOR) CS',
      category: 'facility',
      aliasList: ['for_cs'],
      positionList: [['49.00906', '8.41030']],
      url: 'http://www.for.kit.edu/'
    }, {
      id: '683',
      title: 'Forschungsf\u00f6rderung (FOR) CN',
      category: 'facility',
      aliasList: ['for_cn'],
      positionList: [['49.09304', '8.42786']],
      url: 'http://www.for.kit.edu/'
    }, {
      id: '685',
      title: 'Gastdozentenhaus',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01306', '8.40840']],
      url: 'http://www.gdh.kit.edu/'
    }, {
      id: '688',
      title: 'AKK (Studierendenzentrum)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01112', '8.41487']],
      url: null
    }, {
      id: '689',
      title: 'Sportanlagen',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01638', '8.41589']],
      url: 'http://www.sport.kit.edu/Institut_Sportstaetten.php'
    }, {
      id: '690',
      title: 'Medizinische Dienste (MED) CN',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09243', '8.42706']],
      url: 'http://www.med.kit.edu/'
    }, {
      id: '691',
      title: 'Karlsruhe House of Young Scientists (KHYS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01307', '8.41603']],
      url: 'http://www.khys.kit.edu'
    }, {
      id: '692',
      title: 'Finanzmanagement (FIMA)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00941', '8.41206']],
      url: 'http://www.fima.kit.edu'
    }, {
      id: '693',
      title: 'KIT-Archiv',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00942', '8.41278']],
      url: 'http://www.archiv.kit.edu'
    }, {
      id: '694',
      title: 'Kantine (Campus Nord)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09319', '8.42913']],
      url: 'http://www.aserv.kit.edu/casino.php'
    }, {
      id: '695',
      title: 'Personalentwicklung und Berufliche Ausbildung (PEBA)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01304', '8.42419']],
      url: 'http://www.peba.kit.edu'
    }, {
      id: '696',
      title: 'Werkfeuerwehr',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09592', '8.42674']],
      url: 'http://www.aserv.kit.edu/56.php'
    }, {
      id: '697',
      title: 'KIT-Shuttle (Haltestelle Campus Nord, S\u00fcdtor)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09132', '8.42808']],
      url: null
    }, {
      id: '698',
      title: 'Rechtsangelegenheiten (RECHT)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09304', '8.42786']],
      url: 'http://www.recht.kit.edu'
    }, {
      id: '699',
      title: 'Medizinische Dienste (MED) CS',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01191', '8.40927']],
      url: null
    }, {
      id: '700',
      title: 'KIT-Shuttle (Haltestelle Campus Ost)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.02099', '8.43105']],
      url: null
    }, {
      id: '701',
      title: 'Bibliothek (Campus Nord)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09464', '8.42830']],
      url: 'http://www.bibliothek.kit.edu'
    }, {
      id: '703',
      title: 'Fortbildungszentrum f\u00fcr Technik und Umwelt',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09137', '8.42730']],
      url: 'http://www.fortbildung.kit.edu/'
    }, {
      id: '704',
      title: 'Studierendenservice',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00976', '8.41180']],
      url: 'http://www.sle.kit.edu'
    }, {
      id: '705',
      title: 'Personalservice (PSE) CN',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09304', '8.42786']],
      url: 'http://www.pse.kit.edu'
    }, {
      id: '707',
      title: 'Einkauf, Verkauf und Materialwirtschaft (EVM)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09347', '8.42796']],
      url: 'http://www.evm.kit.edu'
    }, {
      id: '711',
      title: 'Personalservice (PSE) CS',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00943', '8.41154']],
      url: 'http://www.pse.kit.edu'
    }, {
      id: '712',
      title: 'Bibliothek (Campus S\u00fcd)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01101', '8.41642']],
      url: 'http://www.bibliothek.kit.edu/cms/'
    }, {
      id: '715',
      title: 'House of Competence (HOC)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01307', '8.41603']],
      url: 'http://www.hoc.kit.edu/'
    }, {
      id: '716',
      title: 'Studentenwerk',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01188', '8.41756']],
      url: null
    }, {
      id: '717',
      title: 'Scientific Computing Center (SCC), CS',
      category: 'facility',
      aliasList: ['scc', 'iwr', 'rz', 'rechenzentrum', 'hik'],
      positionList: [['49.01227', '8.40809']],
      url: 'https://www.scc.kit.edu'
    }, {
      id: '718',
      title: 'Internationales (INTL)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01031', '8.41889']],
      url: 'http://www.intl.kit.edu/intl/'
    }, {
      id: '720',
      title: 'International Students Office (INTL)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01031', '8.41889']],
      url: 'http://www.intl.kit.edu/istudent/'
    }, {
      id: '721',
      title: 'International Scholars & Welcome Office (Campus Nord)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09309', '8.42787']],
      url: 'http://www.intl.kit.edu/iforscher/'
    }, {
      id: '723',
      title: 'Facility Management (FM)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00943', '8.41195']],
      url: 'http://www.fm.kit.edu/'
    }, {
      id: '724',
      title: 'International Scholars & Welcome Office (Campus S\u00fcd)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00994', '8.41921']],
      url: 'http://www.intl.kit.edu/iforscher/'
    }, {
      id: '725',
      title: 'Mensa',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01196', '8.41684']],
      url: 'http://www.studentenwerk-karlsruhe.de/de/essen/'
    }, {
      id: '726',
      title: 'S\u00fcdwestdeutsches Archiv f\u00fcr Architektur und Ingenieurbau (saai)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00923', '8.41506']],
      url: 'http://www.saai.kit.edu/'
    }, {
      id: '727',
      title: 'Sicherheit und Umwelt (SUM)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09417', '8.43605']],
      url: 'http://www.sum.kit.edu/'
    }, {
      id: '728',
      title: 'Studienzentrum f\u00fcr Sehgesch\u00e4digte (SZS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01194', '8.41019']],
      url: 'https://www.szs.kit.edu'
    }, {
      id: '729',
      title: 'Laboratorium f\u00fcr Elektronenmikroskopie (LEM)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01254', '8.41049']],
      url: 'https://www.lem.kit.edu'
    }, {
      id: '730',
      title: 'KIT-Shuttle (Haltestelle Campus S\u00fcd)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01101', '8.41412']],
      url: null
    }, {
      id: '732',
      title: 'Zukunftscampus',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00937', '8.41179']],
      url: 'http://www.zukunftscampus.kit.edu/'
    }, {
      id: '734',
      title: 'Lernzentrum am Fasanenschl\u00f6sschen (Geb. 30.28)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01274', '8.41250']],
      url: null
    }, {
      id: '736',
      title: 'Meteorologie und Klimaforschung - Atmosph\u00e4rische Aerosolforschung (IMK-AAF) ',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09546', '8.42938']],
      url: null
    }, {
      id: '739',
      title: 'Meteorologie und Klimaforschung - Atmosph\u00e4rische Spurenstoffe und Fernerkundung (IMK-ASF)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09394', '8.43644']],
      url: 'http://www.imk-asf.kit.edu/'
    }, {
      id: '741',
      title: 'Meteorologie und Klimaforschung  - Forschungsbereich Troposph\u00e4re (IMK-TRO)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01295', '8.41103']],
      url: 'http://www.imk-tro.kit.edu/'
    }, {
      id: '743',
      title: 'Schwerbehindertenvertretung (SBV) Campus S\u00fcd',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01025', '8.41179']],
      url: 'http://www.sbv.kit.edu/'
    }, {
      id: '745',
      title: 'Zentrum f\u00fcr Mediales Lernen (ZML)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00751', '8.40425']],
      url: 'http://www.zml.kit.edu/'
    }, {
      id: '746',
      title: 'MINT-Kolleg Unterrichtsgeb\u00e4ude (Kapellenstra\u00dfe 17)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00644', '8.41367']],
      url: null
    }, {
      id: '747',
      title: 'Materialpr\u00fcfungsanstalt (MPA)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01392', '8.42155']],
      url: 'http://www.mpa.kit.edu/'
    }, {
      id: '748',
      title: 'Scientific Computing Center (SCC), CN',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09577', '8.43297']],
      url: 'http://www.scc.kit.edu/'
    }, {
      id: '749',
      title: 'Sprachenzentrum (Geb. 50.20)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01035', '8.41879']],
      url: 'https://www.spz.kit.edu/'
    }, {
      id: '750',
      title: 'MINT-Kolleg (Geb. 50.20)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01035', '8.41879']],
      url: 'http://www.mint-kolleg.kit.edu/'
    }, {
      id: '751',
      title: 'Programmleitungen',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09314', '8.43626']],
      url: null
    }, {
      id: '752',
      title: 'PTKA - Produktion und Fertigungstechnologien (PFT) 436 C 08',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09489', '8.43471']],
      url: 'http://www.ptka.kit.edu/pft/'
    }, {
      id: '753',
      title: 'PTKA - Baden-W\u00fcrttemberg Programme (BWP) 438 C/D 08',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09460', '8.43509']],
      url: 'http://www.ptka.kit.edu/bwp/'
    }, {
      id: '754',
      title: 'PTKA - Wassertechnologie und Entsorgung (WTE) 9675 C/D 08',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09435', '8.43502']],
      url: 'http://www.ptka.kit.edu/wte/'
    }, {
      id: '755',
      title: 'AStA (im Mensageb\u00e4ude)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01196', '8.41684']],
      url: 'http://www.asta-kit.de/'
    }, {
      id: '756',
      title: 'Fachschaft Architektur',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01096', '8.41084']],
      url: 'http://www.fsarchkit.de/wordpress/'
    }, {
      id: '757',
      title: 'Fachschaft Bauingenieurwesen',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01025', '8.41548']],
      url: 'http://www.fs-bau.kit.edu/'
    }, {
      id: '758',
      title: 'Fachschaft Chemie und Biowissenschaften',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01266', '8.41389']],
      url: 'http://fs-chembio.usta.de/index.html'
    }, {
      id: '759',
      title: 'Fachschaft Elektro- und Informationstechnik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01070', '8.41277']],
      url: 'https://fachschaft.etec.uni-karlsruhe.de/'
    }, {
      id: '760',
      title: 'Fachschaft Geistes- und Sozialwissenschaften',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01351', '8.41345']],
      url: 'http://www.geistsoz.de/'
    }, {
      id: '761',
      title: 'Fachschaft Geod\u00e4sie und Geoinformatik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01096', '8.41084']],
      url: 'http://www.fachschaft-gug.de/'
    }, {
      id: '762',
      title: 'Fachschaft Geowissenschaften',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00970', '8.41693']],
      url: 'http://fs-geo.bio-geo.uni-karlsruhe.de/'
    }, {
      id: '763',
      title: 'Fachschaft Informatik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01386', '8.41949']],
      url: 'http://www.fsmi.uni-karlsruhe.de/'
    }, {
      id: '764',
      title: 'Fachschaft Kunstgeschichte',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01096', '8.41084']],
      url: 'http://kg.ikb.kit.edu/68.php'
    }, {
      id: '765',
      title: 'Fachschaft Maschinenbau/Chemieingenieurwesen',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00954', '8.41430']],
      url: 'http://www.fs-fmc.kit.edu/'
    }, {
      id: '766',
      title: 'Fachschaft Mathematik und Informatik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01386', '8.41949']],
      url: 'http://www.fsmi.uni-karlsruhe.de/'
    }, {
      id: '767',
      title: 'Fachschaft Physik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01240', '8.41089']],
      url: 'http://fachschaft.physik.kit.edu/drupal/'
    }, {
      id: '768',
      title: 'Fachschaft Sport',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01565', '8.41632']],
      url: 'http://www.sport.kit.edu/fachschaft/'
    }, {
      id: '769',
      title: 'Fachschaft WiWi',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01265', '8.40663'], ['49.00918', '8.40966']],
      url: 'http://www.fachschaft.org/'
    }, {
      id: '770',
      title: 'KinderUniversum',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01079', '8.41938']],
      url: null
    }, {
      id: '771',
      title: 'Germanistik: Literatur, Sprache, Medien (GER), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01322', '8.41354']],
      url: 'https://www.geistsoz.kit.edu/germanistik/'
    }, {
      id: '772',
      title: 'Zentrum f\u00fcr Information und Beratung (zib)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01038', '8.41248']],
      url: 'http://www.sle.kit.edu/vorstudium/zib.php'
    }, {
      id: '773',
      title: 'Angewandte Materialien - Angewandte Werkstoffphysik (IAM-AWP), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.10080', '8.43394']],
      url: 'http://www.iam.kit.edu/awp/'
    }, {
      id: '774',
      title: 'Angewandte Materialien - Werkstoff- und Biomechanik (IAM-WBM), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09970', '8.43420']],
      url: 'http://www.iam.kit.edu/wbm/'
    }, {
      id: '775',
      title: 'Angewandte Materialien -\u00a0Werkstoffkunde (IAM-WK)\u00a0Geb\u00e4ude 10.91, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00999', '8.41346']],
      url: 'http://www.iam.kit.edu/wk/'
    }, {
      id: '776',
      title: 'Angewandte Materialien -\u00a0Werkstoffkunde (IAM-WK)\u00a0Geb\u00e4ude 10.96, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00986', '8.41411']],
      url: null
    }, {
      id: '777',
      title: 'Angewandte Materialien \u2013 Keramische Werkstoffe und Technologien (IAM-KWT), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01244', '8.42549']],
      url: 'http://www.iam.kit.edu/km/'
    }, {
      id: '778',
      title: 'Angewandte Materialien \u2013 Computational Materials Science (IAM-CMS)\u00a0, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00999', '8.41346']],
      url: 'http://www.iam.kit.edu/cms/'
    }, {
      id: '779',
      title: 'Angewandte Materialien - Energiespeichersysteme (IAM-ESS)\u00a0, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.09652', '8.43166']],
      url: 'http://www.iam.kit.edu/ess/'
    }, {
      id: '781',
      title: '449',
      category: 'building',
      aliasList: [],
      positionList: [['49.09577', '8.43297']],
      url: ''
    }, {
      id: '782',
      title: '01.87',
      category: 'building',
      aliasList: [],
      positionList: [['49.00510', '8.41154']],
      url: null
    }, {
      id: '783',
      title: '05.02',
      category: 'building',
      aliasList: [],
      positionList: [['49.00644', '8.41367']],
      url: null
    }, {
      id: '784',
      title: 'Maschinenbau, oberer und mittlerer H\u00f6rsaal (10.91)',
      category: 'lecturehall',
      aliasList: [],
      positionList: [['49.00992', '8.41285']],
      url: null
    }, {
      id: '785',
      title: '30.28',
      category: 'building',
      aliasList: [],
      positionList: [['49.01296', '8.41222']],
      url: null
    }, {
      id: '786',
      title: '01.85',
      category: 'building',
      aliasList: [],
      positionList: [['49.00887', '8.41043']],
      url: null
    }, {
      id: '787',
      title: 'Karlsruhe Decision and Design Laboratory',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00887', '8.41043']],
      url: 'http://csl.anthropomatik.kit.edu/2434.php'
    }, {
      id: '788',
      title: '3300',
      category: 'building',
      aliasList: [],
      positionList: [['49.01121', '8.39536']],
      url: null
    }, {
      id: '789',
      title: '01.86',
      category: 'building',
      aliasList: [],
      positionList: [['49.00631', '8.41061']],
      url: null
    }, {
      id: '790',
      title: 'Bereich I \u2013 Biologie, Chemie und Verfahrenstechnik (Campus S\u00fcd)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00947', '8.41175']],
      url: 'http://www.kit.edu/b1/index.php'
    }, {
      id: '791',
      title: 'Bereich I - Biologie, Chemie und Verfahrenstechnik (Campus Nord)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09451', '8.43404']],
      url: 'http://www.kit.edu/b1/index.php'
    }, {
      id: '792',
      title: 'Bereich II - Informatik, Wirtschaft und Gesellschaft (CS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00949', '8.41163']],
      url: 'http://www.kit.edu/b2/index.php'
    }, {
      id: '793',
      title: 'Bereich II - Informatik, Wirtschaft und Gesellschaft (CN)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09483', '8.43473']],
      url: 'http://www.kit.edu/b2/index.php'
    }, {
      id: '794',
      title: 'Bereich III - Maschinenbau und Elektrotechnik (CS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00949', '8.41163']],
      url: 'http://www.kit.edu/b3/index.php'
    }, {
      id: '795',
      title: 'Bereich III - Maschinenbau und Elektrotechnik (CN)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09461', '8.43408']],
      url: 'http://www.kit.edu/b3/index.php'
    }, {
      id: '796',
      title: 'Bereich IV - Nat\u00fcrliche und gebaute Umwelt (CS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00948', '8.41162']],
      url: 'http://www.kit.edu/b4/index.php'
    }, {
      id: '797',
      title: 'Bereich IV - Nat\u00fcrliche und gebaute Umwelt (CN)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09387', '8.43643']],
      url: 'http://www.kit.edu/b4/index.php'
    }, {
      id: '798',
      title: 'Bereich V - Physik und Mathematik (CS)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00948', '8.41162']],
      url: 'http://www.kit.edu/b5/index.php'
    }, {
      id: '799',
      title: 'Bereich V - Physik und Mathematik (CN)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09484', '8.43468']],
      url: 'http://www.kit.edu/b5/index.php'
    }, {
      id: '800',
      title: '440',
      category: 'building',
      aliasList: [],
      positionList: [['49.09308', '8.43615']],
      url: null
    }, {
      id: '801',
      title: 'Programm BioGrenzfl\u00e4chen',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09308', '8.43620']],
      url: 'http://www.kit.edu/forschen/1303.php'
    }, {
      id: '804',
      title: '9675',
      category: 'building',
      aliasList: ['containergeb\u00e4ude'],
      positionList: [['49.09428', '8.43507']],
      url: null
    }, {
      id: '805',
      title: 'KIT-Zentrum f\u00fcr Klima und Umwelt',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09428', '8.43509']],
      url: 'http://www.klima-umwelt.kit.edu/index.php'
    }, {
      id: '806',
      title: 'KIT-Zentrum Energie',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09429', '8.43509']],
      url: 'http://www.energie.kit.edu/index.php'
    }, {
      id: '807',
      title: 'Projekttr\u00e4ger Karlsruhe (PTKA)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09429', '8.43509']],
      url: 'http://www.ptka.kit.edu/'
    }, {
      id: '808',
      title: '01.80',
      category: 'building',
      aliasList: [],
      positionList: [['49.00987', '8.41098']],
      url: null
    }, {
      id: '809',
      title: '20.40',
      category: 'building',
      aliasList: [],
      positionList: [['49.01126', '8.41137']],
      url: null
    }, {
      id: '810',
      title: '30.48',
      category: 'building',
      aliasList: [],
      positionList: [['49.01317', '8.41478']],
      url: null
    }, {
      id: '811',
      title: '30.49',
      category: 'building',
      aliasList: [],
      positionList: [['49.01351', '8.41538']],
      url: null
    }, {
      id: '812',
      title: 'Theorie der Kondensierten Materie, Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01307', '8.41076']],
      url: 'https://www.tkm.kit.edu/'
    }, {
      id: '813',
      title: 'Fachbibliothek WIWI',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01303', '8.37530']],
      url: 'https://www.wiwi.kit.edu/bib.php'
    }, {
      id: '814',
      title: 'Lehrstuhl f\u00fcr Industrie\u00f6konomik, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01302', '8.37533']],
      url: 'http://io.econ.kit.edu/'
    }, {
      id: '815',
      title: 'Lehrstuhl f\u00fcr Finanzwissenschaft und Public Management, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01301', '8.37530']],
      url: 'http://fiwi.econ.kit.edu/index.php'
    }, {
      id: '816',
      title: 'Lehrstuhl f\u00fcr Makro\u00f6konomik, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01302', '8.37529']],
      url: 'http://macro.econ.kit.edu/'
    }, {
      id: '817',
      title: 'Lehrstuhl f\u00fcr Netzwerk\u00f6konomie, Institut f\u00fcr Volkswirtschaftslehre (ECON',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00891', '8.41131']],
      url: 'http://netze.econ.kit.edu/'
    }, {
      id: '818',
      title: 'Lehrstuhl f\u00fcr \u00d6konometrie, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01303', '8.37528']],
      url: 'http://statistik.econ.kit.edu/'
    }, {
      id: '819',
      title: 'Lehrstuhl f\u00fcr Politische \u00d6konomie, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00895', '8.41037']],
      url: 'http://polit.econ.kit.edu/deutsch/index.php'
    }, {
      id: '820',
      title: 'Lehrstuhl f\u00fcr Wirtschaftspolitik, Institut f\u00fcr Volkswirtschaftslehre (ECON)',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00494', '8.41088']],
      url: 'http://wipo.econ.kit.edu/'
    }, {
      id: '822',
      title: '\u00d6konomie und \u00d6kologie des Wohnungsbaus (OEW), Fachgebiet Immobilienwirtschaft',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01237', '8.40929']],
      url: 'http://www.oew.kit.edu/'
    }, {
      id: '823',
      title: 'KIT-Fakult\u00e4t f\u00fcr Wirtschaftswissenschaften, Pr\u00fcfungssekretariat',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00915', '8.40929']],
      url: 'http://www.wiwi.kit.edu/Pruefungssekretariat.php'
    }, {
      id: '824',
      title: 'KIT-Fakult\u00e4t f\u00fcr Wirtschaftswissenschaften, Gesch\u00e4ftsstelle  ',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00915', '8.40934']],
      url: 'http://www.wiwi.kit.edu/fakultaet.php'
    }, {
      id: '825',
      title: 'Soziologie, Medien- und Kulturwissenschaften (ISMK), Institut f\u00fcr ',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01025', '8.41176']],
      url: 'http://geistsoz.kit.edu/ismk/'
    }, {
      id: '828',
      title: 'Institut f\u00fcr Operations Research (IOR), Bereich Diskrete Optimierung und Logistik',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00914', '8.40928']],
      url: 'http://dol.ior.kit.edu/'
    }, {
      id: '829',
      title: 'Institut f\u00fcr Operations Research (IOR), Bereich Kontinuierliche Optimierung',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01300', '8.37529']],
      url: 'http://kop.ior.kit.edu/'
    }, {
      id: '830',
      title: 'Institut f\u00fcr Operations Research (IOR), Bereich Stochastische Modellierung und Optimierung',
      category: 'institute',
      aliasList: [],
      positionList: [['49.01305', '8.37524']],
      url: 'http://smo.ior.kit.edu/'
    }, {
      id: '831',
      title: 'Institut f\u00fcr Operations Research (IOR), Bereich Analytics and Statistics',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00493', '8.41080']],
      url: 'http://as.ior.kit.edu/index.php'
    }, {
      id: '832',
      title: '09.20',
      category: 'building',
      aliasList: [],
      positionList: [['49.00944', '8.39324']],
      url: null
    }, {
      id: '833',
      title: '09.21',
      category: 'building',
      aliasList: [],
      positionList: [['49.01305', '8.37527']],
      url: null
    }, {
      id: '834',
      title: '9651',
      category: 'building',
      aliasList: [],
      positionList: [['49.09502', '8.43545']],
      url: null
    }, {
      id: '835',
      title: 'Schwerbehindertenvertretung (SBV) Campus Nord',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09361', '8.42831']],
      url: 'http://www.sbv.kit.edu/'
    }, {
      id: '836',
      title: 'Strategische Entwicklung und Kommunikation (SEK)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09365', '8.42834']],
      url: 'http://www.sek.kit.edu/index.php'
    }, {
      id: '837',
      title: 'Technikzuk\u00fcnfte, Institut f\u00fcr ',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00952', '8.39331']],
      url: 'http://www.itz.kit.edu/index.php'
    }, {
      id: '838',
      title: 'Angewandte Betriebswirtschaftslehre und Unternehmensf\u00fchrung (IBU), Institut f\u00fcr',
      category: 'institute',
      aliasList: [],
      positionList: [['49.00905', '8.40956']],
      url: 'http://www.ibu.kit.edu/index.php'
    }, {
      id: '839',
      title: 'Maschinenbau, KIT-Fakult\u00e4t f\u00fcr',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00987', '8.41365']],
      url: 'http://www.mach.kit.edu/index.php'
    }, {
      id: '840',
      title: '70.03',
      category: 'building',
      aliasList: [],
      positionList: [['49.02215', '8.43354']],
      url: null
    }, {
      id: '841',
      title: '145',
      category: 'building',
      aliasList: [],
      positionList: [['49.09323', '8.42917']],
      url: null
    }, {
      id: '842',
      title: '40.50',
      category: 'building',
      aliasList: [],
      positionList: [['49.01418', '8.41438'], ['49.01418', '8.41438']],
      url: null
    }, {
      id: '843',
      title: '40.51',
      category: 'building',
      aliasList: [],
      positionList: [['49.01414', '8.41491'], ['49.01414', '8.41491']],
      url: null
    }, {
      id: '844',
      title: 'Evaluationsstelle (SEK-QM)',
      category: 'facility',
      aliasList: [],
      positionList: [['49.00967', '8.41144']],
      url: 'https://www.sek.kit.edu/215.php'
    }, {
      id: '845',
      title: '40.44',
      category: 'building',
      aliasList: [],
      positionList: [['49.01615', '8.41488']],
      url: null
    }, {
      id: '846',
      title: 'Hochschulsport',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01605', '8.41686']],
      url: 'http://www.sport.kit.edu/Hochschulsport.php'
    }, {
      id: '847',
      title: 'Botanischer Garten',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01325', '8.41928']],
      url: 'http://www.botanik.kit.edu/garten/index.php'
    }, {
      id: '848',
      title: '20.29 (Containergeb\u00e4ude)',
      category: 'building',
      aliasList: [],
      positionList: [['49.01230', '8.40858']],
      url: null
    }, {
      id: '849',
      title: '01.90',
      category: 'building',
      aliasList: [],
      positionList: [['49.00965', '8.41101']],
      url: null
    }, {
      id: '850',
      title: '01.99',
      category: 'building',
      aliasList: [],
      positionList: [['49.00966', '8.40757']],
      url: null
    }, {
      id: '851',
      title: '09.22',
      category: 'building',
      aliasList: [],
      positionList: [['48.99330', '8.34448']],
      url: null
    }, {
      id: '852',
      title: '09.23',
      category: 'building',
      aliasList: [],
      positionList: [['49.00519', '8.39072']],
      url: null
    }, {
      id: '853',
      title: '70.04',
      category: 'building',
      aliasList: [],
      positionList: [['49.02272', '8.43344']],
      url: null
    }, {
      id: '854',
      title: 'Mitfahrbank (Campus S\u00fcd): Bibliothek',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01086', '8.41658']],
      url: null
    }, {
      id: '855',
      title: 'Mitfahrbank (Campus S\u00fcd): Schranke zur Westausfahrt',
      category: 'facility',
      aliasList: [],
      positionList: [['49.01166', '8.40886']],
      url: null
    }, {
      id: '856',
      title: 'Mitfahrbank (Campus Nord): S\u00fcdtor / FTU',
      category: 'facility',
      aliasList: [],
      positionList: [['49.09126', '8.42776']],
      url: null
    }]
  }
}
